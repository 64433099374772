import { Pipe, PipeTransform } from "@angular/core";


@Pipe({
    name: 'dataFormattingPipe'
})

export class dataFormatPipe implements PipeTransform {
    constructor() { }

    public transform(pNumber: any, col: string, pUnitsFlag: any) {
        let functionReturnValue = '';
        if (["T_CHANGE", "C_CHANGE"].includes(col)) {
            functionReturnValue = pNumber + '%';
        } else if ((col === 'CMVALUE' || col === 'YTD' || col === 'CLASSAVG') && pUnitsFlag) {
            if (pNumber !== null || pNumber !== '') {
                switch (pUnitsFlag) {
                    case "81":
                        //dollar w/ 0 decimals
                        functionReturnValue = '$' + this.formatNumber(pNumber, 0, -1, true);
                        break;
                    case "82":
                        //percent w/ 1 decimal
                        functionReturnValue = this.formatNumber(pNumber, 1, -1) + "%";
                        break;
                    case "83":
                        //units w/ 1 decimal
                        functionReturnValue = this.formatNumber(pNumber, 1, -1);
                        break;
                    case "84":
                        //units w/ 0 decimal
                        functionReturnValue = this.formatNumber(pNumber, 0, -1, true);
                        break;
                    case "85":
                        //ratio w/ 2 decimal
                        functionReturnValue = this.formatNumber(pNumber, 2, -1) + ":1";
                        break;
                    case "86":
                        //units w/ 2 decimal
                        functionReturnValue = this.formatNumber(pNumber, 2, -1);
                        break;
                    case "87":
                        //percent w/ 0 decimal
                        functionReturnValue = this.formatNumber(pNumber, 0, -1) + "%";
                        break;
                    case "88":
                        //dollar w/ 2 decimals
                        functionReturnValue = '$' + this.formatNumber(pNumber, 2, -1, true);
                        break;
                    case "810":
                        //percent w/ 2 decimal
                        functionReturnValue = this.formatNumber(pNumber, 2, -1) + "%";
                        break;
                    case "811":
                        //Yes/No
                        functionReturnValue = pNumber == "1" ? "Yes" : "No";
                        break;
                    case "813":
                        //ratio w/ 1 decimal  0 if 0
                        if (pNumber != "0") {
                            functionReturnValue = this.formatNumber(pNumber, 1, -1) + ":1";
                        }
                        else {
                            functionReturnValue = "0";
                        }

                        break;
                    default:
                        functionReturnValue = this.formatNumber(pNumber, 0, -1);
                        break;

                }
            }
        } else {
            functionReturnValue = pNumber;
        }
        return functionReturnValue;
    }

    private formatNumber(sNumber: number, NumDigitsAfterDecimal, UseParensForNegativeNumbers, isDollarVal?) {
        let sRtnValue;
        if (sNumber !== null) {
            const value = parseFloat(sNumber.toString()).toFixed(NumDigitsAfterDecimal);
            if (isDollarVal) {
                sRtnValue = Number(value).toLocaleString();
            } else {
                sRtnValue = value;
            }

            sRtnValue = UseParensForNegativeNumbers == -1 ? (sNumber < 0 ? "(" + sRtnValue.replace("-", "").trim() + ")" : sRtnValue) : sRtnValue;
        }
        return sRtnValue;
    }

}
