import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges } from '@angular/core';
import { Subscription } from 'rxjs';
import { CRGridSearch } from 'src/app/modules/workout-sales/interfaces/custom-reports.interface';


export interface TimeframeType {
  value: number;
  viewValue: string;
}
@Component({
  selector: 'app-drop-down-list',
  templateUrl: './drop-down-list.component.html',
  styleUrls: ['./drop-down-list.component.scss']
})
export class DropDownListComponent implements OnInit, OnDestroy {

  readonly subscription: Subscription = new Subscription();
  public selectOptions = [];
  public defaultSelected: any;
  public title = '';
  @Output() updateCompanySearch: EventEmitter<CRGridSearch> = new EventEmitter<CRGridSearch>();
  @Output() clearFilter: EventEmitter<string> = new EventEmitter<string>();
  @Input() requiredMsg;
  @Input() companyForServiceAnalysis;
  @Input() dropdownType;
  @Input() dropdownTitle;
  private _selectDropdownData :any;
  placeholderTitle: string;

  get dropdownData() {
    return this._selectDropdownData;
  }
  @Input() set dropdownData(value: any) {
    this.defaultSelected = '';
    this._selectDropdownData = value;

  }
  @Input() set removedFilterKey(value: any) {
    if (value?.key === this.dropdownType) {
      this.defaultSelected = '';
    } else if(value?.key === 'company' && this.dropdownType === 'hierarchy'){
      this.defaultSelected = '';
    }
  }
  constructor(
  ) {

  }

  ngOnInit(): void {
  }


  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
  setSelectedValue(event) {
    let element = event?.source?.value;
    this.defaultSelected = element;
    const searchChips = { key: this.dropdownType, value: element };
    this.updateCompanySearch.emit(searchChips);
  }


  onClear() {
    this.defaultSelected = '';
    this.clearFilter.emit(this.dropdownType)
  }

}
