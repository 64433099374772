import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { forkJoin, Observable, Subscription } from 'rxjs';
import { HIERARCHY_TYPE, reportUrlType } from 'src/app/constants/constants';
import { ToastService } from 'src/app/services/toast.service';
import { SpinnerService } from '../../../../services/spinner.service';
import { CRGridSearch } from '../../../workout-sales/interfaces/custom-reports.interface';
import { CustomReportsApiService } from '../../services/api/custom-report-api.service';
import { CustomReportsFiltersService } from '../../services/custom-report-filters.service';
import { CustomReportsService } from '../../services/custom-report.service';
import { getMonthAndYear, getObjFromFilteredArray } from '../../utils/common-utils';
import { LexusDealerAtGlanceReport } from '../reportConstants';
import * as _ from 'lodash';
import { HrcyMock } from '../../mock/mock';

@Component({
  selector: 'app-lexus-dealer-at-glance',
  templateUrl: './lexus-dealer-at-glance.component.html',
  styleUrls: ['./lexus-dealer-at-glance.component.scss']
})
export class LexusDealerAtGlanceComponent implements OnInit {

  @Output() updateSearch: EventEmitter<CRGridSearch> = new EventEmitter<CRGridSearch>();
  public displayColumsForTopTenDealerDealer: string[] = [];
  public lexusDealerAtGlanceReportDataSource = new MatTableDataSource([]);
  public currentTabIndex: number = 0;
  public lexusDealerAtGlancejsonObj;
  public isExpansionEnabled: boolean = false;
  public forgerockUrl: string = '';
  public subscription: Subscription = new Subscription();
  public selectedTabIndex = [0];
  private previousFilteredValue;
  public sources: Observable<any>[] = [];
  selectedFilter: CRGridSearch[];
  reportType: any;
  isFilterSame: boolean;
  previousFilter: any;
  selectedHierachy: any;
  public obj = Object.values;
  isProfileTable: boolean = false;
  selectedDateFilter: string;

  constructor(
    private readonly spinnerService: SpinnerService,
    private filterService: CustomReportsFiltersService,
    private customReportsApiService: CustomReportsApiService,
    private readonly customReportsService: CustomReportsService,
    public toast: ToastService,
    public route: Router
  ) {
    const reportTypeNameFromUrl = this.route.url.split('/').pop();
    this.reportType = reportUrlType[reportTypeNameFromUrl];
    this.getLexusDealerAtGlanceReport();
  }

  ngOnInit(): void {
    this.subscribeReportData();
    this.displayProfileSection();
  }

  public tabChange(tabIndex: number) {
    this.currentTabIndex = tabIndex;
    this.sources = [];
    const index = this.selectedTabIndex.findIndex(x => x === tabIndex);
    this.spinnerService.displaySpinner();
    if (index === -1 && this.currentTabIndex !== 4 && this.currentTabIndex !== 5) {
      this.selectedTabIndex.push(tabIndex);
      this.filterService.setSelectedValues(this.selectedFilter);
    } else {
      this.spinnerService.hideSpinner();
    }
  }

  public removeAllFilters(): void {
    this.filterService?.setRemovedValues({});
  }

  public getTabDataSource(tabIndex) {
    const obj = getObjFromFilteredArray(this.selectedFilter);
    this.selectedDateFilter = obj['monthYear'];
    const dateObj = getMonthAndYear(obj['monthYear']);
    const filterObj = obj['dealer'];
    const levelName = HrcyMock.find(x => x.id === obj['hierarchy'])?.name;
    const requestObj = {
      month: dateObj.searchMonth,
      year: dateObj.searchYear,
      hierarchy_level_type_rcid: obj['hierarchy'],
      hierarchy_level_member_rcid: filterObj.MEMBER_CODE,
      view_id: this.getViewId(tabIndex, levelName)
    }
    if (tabIndex === 0) {
      this.sources = [this.customReportsApiService.getLexusDAAGForLexusCompleteMainteance(requestObj).pipe(this.customReportsService.handleError()?.bind(this))
      ]
    }
    else if (tabIndex === 1) {
      this.sources = [this.customReportsApiService.getLexusDAAGForServiceRetention(requestObj).pipe(this.customReportsService.handleError()?.bind(this))
      ]
    }
    // else if (tabIndex === 2) {
    //   this.sources = [this.customReportsApiService.getLexusDAAGForServiceRetention(requestObj).pipe(this.customReportsService.handleError()?.bind(this))
    //   ]
    // } 
    else if (tabIndex === 3) {
      this.sources = [this.customReportsApiService.getLexusDAAGForRoadSide(requestObj).pipe(this.customReportsService?.handleError()?.bind(this))
      ]
    }
    // else if (tabIndex === 4) {
    //   this.sources = [this.customReportsApiService.getLexusDAAGForVolume(requestObj).pipe(this.customReportsService?.handleError()?.bind(this))
    //   ]
    // } else if (tabIndex === 5) {
    //   this.sources = [this.customReportsApiService.getLexusDAAGForServiceCapacity(requestObj).pipe(this.customReportsService?.handleError()?.bind(this))
    //   ]
    // } 
    else if (tabIndex === 6) {
      requestObj['hierarchy'] = filterObj.HIERARCHY_ID,
        this.sources = [this.customReportsApiService.getLexusDAAGForDealerProfile(requestObj).pipe(this.customReportsService?.handleError()?.bind(this))
        ]
    } else {
      this.spinnerService.hideSpinner();
    }
    this.displayProfileSection();
    return this.sources;

  }


  public subscribeReportData() {
    this.subscription.add(
      this.filterService.getSelectedValues().subscribe({
        next: (filteredValues) => {
          console.log(filteredValues, 'filtered values')
          this.spinnerService.displaySpinner();
          if (filteredValues?.length > 0) {
            this.selectedFilter = filteredValues;
            this.isFilterSame = _.isEqual(this.selectedFilter, this.previousFilter);
            if (this.isFilterSame === false) {
              this.selectedTabIndex = [];
              this.selectedTabIndex.push(this.currentTabIndex);
            }
            if (this.lexusDealerAtGlancejsonObj?.tabList[this.currentTabIndex]?.datasource?.length > 0 || this.isFilterSame === false) {
              this.getLexusDealerAtGlanceReport(); //initialise to empty object
            }
            this.mapResponse();
          }
        }, error: (error) => {
          console.error('Error handler:', error)
          this.spinnerService.hideSpinner();
        }
      })
    )
  }

  public async mapResponse() {
    let currentTableResponse = [];
    await this.getTabDataSource(this.currentTabIndex);
    this.subscription.add(forkJoin(this.sources).subscribe({
      next: (resp) => {
        if (this.currentTabIndex === 0) {
          if (resp.length > 0 && resp[0].status === 200) {
            const tableHeaders = this.getDisplayedHeaders(resp[0]?.body[0]?.reportHeaderData[0][0], '1', '6', '4');
            const obj = [{
              "datasourceName": "LEXUSCompleteMaintainDataSource",
              "tableHeader": tableHeaders,
              "columnHeaderName": this.obj(resp[0]?.body[0]?.reportHeaderData[1][0]),
              "tableData": resp[0]?.body[0]?.reportData,
              "sectionTitle": resp[0]?.body[0]?.reportHeaderData[1][0]?.SECTIONTITLE,
            }];
            currentTableResponse.push(obj);
          }
        }
        if (this.currentTabIndex === 1) {
          if (resp.length > 0 && resp[0].status === 200) {
            const tableHeaders = this.getDisplayedHeaders(resp[0]?.body[0]?.reportHeaderData[0][0], '1', '6', '3');
            const obj = [{
              "datasourceName": "LEXUServiceRetentionDataSource",
              "tableHeader": tableHeaders,
              "columnHeaderName": this.obj(resp[0]?.body[0]?.reportHeaderData[1][0]),
              "tableData": resp[0]?.body[0]?.reportData,
              "sectionTitle": resp[0]?.body[0]?.reportHeaderData[1][0]?.SECTIONTITLE,
            }];
            currentTableResponse.push(obj);

          }
        }
        if (this.currentTabIndex === 3) {
          if (resp.length > 0 && resp[0].status === 200) {
            const tableHeaders = this.getDisplayedHeaders(resp[0]?.body[0]?.reportHeaderData[0][0], '1', '6', '4');
            const obj = [{
              "datasourceName": "LEXUSVolumeDataSource",
              "tableHeader": tableHeaders,
              "columnHeaderName": this.obj(resp[0]?.body[0]?.reportHeaderData[1][0]),
              "tableData": resp[0]?.body[0]?.reportData,
              "sectionTitle": resp[0]?.body[0]?.reportHeaderData[1][0]?.SECTIONTITLE,
            }];
            currentTableResponse.push(obj);

          }
        }
        if (this.currentTabIndex === 6) {
          if (resp.length > 0 && resp[0].status === 200) {
            const obj = [{
              "datasourceName": "profileDataSource",
              "tableData": resp[0]?.body,
              "isRegionalTable": false,
            }];
            currentTableResponse.push(obj);

          }
        }
        this.lexusDealerAtGlancejsonObj.tabList[this.currentTabIndex].datasource = currentTableResponse;
        console.log(this.lexusDealerAtGlancejsonObj, 'current table responseeeeeeeee')

        this.spinnerService.hideSpinner();
      }, error: (err) => {
        console.error('Error handler: mapping response', err);
        this.spinnerService.hideSpinner();
      }
    })
    )
  }


  ngOnDestroy(): void {
    this.removeAllFilters();
    this.filterService.setSelectedValues([]);
    this.subscription?.unsubscribe();
  }

  public getLexusDealerAtGlanceReport() {
    let tabList = [
      {
        tabName: 'Lexus Complete Maintenance Care (LCMC)',
        datasource: []
      },
      {
        tabName: 'Service Retention',
        datasource: []
      },
      {
        tabName: 'Roadside',
        datasource: []
      },
      {
        tabName: 'Volume',
        datasource: []
      },
      {
        tabName: 'Service Capacity',
        datasource: []
      },
      {
        tabName: 'Programs',
        datasource: []
      },
      {
        tabName: 'Dealer Profile',
        datasource: []
      }
    ];
    this.lexusDealerAtGlancejsonObj = {
      tabList: tabList
    }
  }

  public getViewId(tabIndex: number, level_type) {
    const viewIds = ['lcmc_info', 'service_retention_info', 'ROAD', 'volume_info'];
    return `${level_type?.toLowerCase()}_${viewIds[tabIndex]}`;
  }

  getDisplayedHeaders(reportHeaderData: any, col1: string, col2: string, col3: string) {
    let data: any = [];
    let obj;
    if (this.currentTabIndex === 0) {
      obj = {
        [reportHeaderData.SECTIONTITLE]: col1,
        ' ': 1,
        [reportHeaderData.HISTORYTITLE]: col2,
        [reportHeaderData.MTDTITLE]: col3
      };
    } else if (this.currentTabIndex === 1) {
      obj = {
        [reportHeaderData.SECTIONTITLE]: col1,
        [reportHeaderData.HISTORYTITLE]: col2,
        [reportHeaderData.YTDTITLE]: col3
      }
    } else if (this.currentTabIndex === 3) {
      obj = {
        [reportHeaderData.SECTIONTITLE]: col1,
        ' ': 1,
        [reportHeaderData.HISTORYTITLE]: col2,
        [reportHeaderData.YTDTITLE]: col3
      };
    }
    data.push(obj);
    return data;
  }

  public displayProfileSection() {
    if (this.currentTabIndex === 6) {
      this.isProfileTable = true;

    } else {
      this.isProfileTable = false;
    }
  }

  public hideHeaderColumns(reportHeaderColumns :any) {
    const reportHeaders = _.filter(reportHeaderColumns, x => !(x.includes(x.X_UNITS_FLG) && x.includes(x.X_ACCT_NO)));
    return reportHeaders;
  }

}