import { ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { reportType, reportUrlType } from 'src/app/constants/constants';
import { CRGridSearch } from 'src/app/modules/workout-sales/interfaces/custom-reports.interface';
import { SearchFilterService } from 'src/app/modules/workout-sales/services/search-filter.service';
import { HrcyMock } from '../../mock/mock';
import { CustomReportsFiltersService } from '../../services/custom-report-filters.service';
import * as ExcelJS from 'exceljs';
import * as FileSaver from 'file-saver';
import { SpinnerService } from 'src/app/services/spinner.service';
import { CustomReportsExportPdfService } from '../../services/custom-report-export-pdf.service';


@Component({
  selector: 'app-custom-report-dashboard',
  templateUrl: './custom-report-dashboard.component.html',
  styleUrls: ['./custom-report-dashboard.component.scss']
})
export class CustomReportDashboardComponent implements OnInit {
  public showSpinner: false;

  public searchObject: Array<CRGridSearch> = [];
  public subscription: Subscription = new Subscription();
  public currentSearchFilter: any = [];
  public multiSelectFilter = [];
  public reportTitle: string = '';
  public requiredFilters;
  public selectedIndex = 0;
  public isFirstTimeLoaded: boolean = true;
  selectedDateToFilter: string;
  backBtnTitle: string;
  clearFilterKey: string;
  selectedHierarchyName: string;
  hierarchyDropdownData = HrcyMock;
  dataLoaded: boolean[] = [false, false, false];
  removedKey: any;

  constructor(
    private spinnerService: SpinnerService,
    private filterService: SearchFilterService,
    private route: Router,
    public customerReportFilterService: CustomReportsFiltersService,
    public readonly customReportExportPdfService: CustomReportsExportPdfService,
    public changeDetector: ChangeDetectorRef,

  ) {
    this.subscription.add(this.filterService.getRemovedVal().subscribe((value: CRGridSearch) => {
      if (value && value.key) {
        this.removedKey = value;
      } else {
        this.removedKey = {};
        this.searchObject = [];
      }
    }));


    const reportTypeNameFromUrl = this.route.url.split('/').pop();
    this.reportTitle = reportUrlType[reportTypeNameFromUrl];


    this.reportTitle = reportUrlType[reportTypeNameFromUrl];
    if (this.isReportType()) {
      this.backBtnTitle = 'Back to Report List';
    } else {
      this.backBtnTitle = 'Back to Dashboards';
    }
  }

  ngOnInit(): void {
    this.getFilters();
  }

  public addSelectedDealers(event) {
    this.multiSelectFilter = event;
    if (this.multiSelectFilter.length <= this.selectedIndex) {
      this.selectedIndex = 0;
    }
  }

  public getFilters() {
    switch (this.reportTitle) {
      case reportType.LEXUS_PO_SNAPSHOT: {
        this.requiredFilters = {
          "dateFilter": true

        }
        break;
      }
      case reportType.TCUV_DEALER_GLANCE: {
        this.requiredFilters = {
          "dateFilter": true,
          "multiSelectDealerMemberFilter": true
        }
        break;
      }
      case reportType.LEXUS_SALES_GLANCE: {
        this.requiredFilters = {
          "dateFilter": true,
          "multiSelectDealerMemberFilter": true
        }
        break;
      }
      case reportType.TOYOTA_DEALER_GLANCE: {
        this.requiredFilters = {
          "dateFilter": true,
          "hierarchyDropdownFilter": true,
          "multiSelectHierarchyMemberFilter": true
        }
        break;
      }
      case reportType.LEXUS_DEALER_GLANCE: {
        this.requiredFilters = {
          "dateFilter": true,
          "hierarchyDropdownFilter": true,
          "multiSelectHierarchyMemberFilter": true,
        }
        break;
      }
      case reportType.PARTS_DEPARTMENT: {
        this.requiredFilters = {
          "dateFilter": true,
          "multiSelectDealerMemberFilter": true,
          "companyDropdownFilter": true

        }
        break;
      }
      case reportType.SERVICE_DEPARTMENT: {
        this.requiredFilters = {
          "dateFilter": true,
          "multiSelectDealerMemberFilter": true,
          "companyDropdownFilter": true

        }
        break;
      }
      case reportType.SERVICE_ANALYSIS: {
        this.requiredFilters = {
          "dateFilter": true,
          "multiSelectHierarchyMemberFilter": true,
          "companyDropdownFilter": true,
          "hierarchyDropdownFilter": true   //hierarchyDropdownFilter
        }
        break;
      }
      case reportType.SALES_ANALYSIS_NEW_VEHICLE: {
        this.requiredFilters = {
          "dateFilter": true,
          "multiSelectHierarchyMemberFilter": true,
          "companyDropdownFilter": true,
          "hierarchyDropdownFilter": true
        }
        break;
      }
      case reportType.SALES_ANALYSIS_USED_VEHICLE: {
        this.requiredFilters = {
          "dateFilter": true,
          "multiSelectHierarchyMemberFilter": true,
          "companyDropdownFilter": true,
          "hierarchyDropdownFilter": true
        }
        break;
      }
      case reportType.PARTS_ANALYSIS: {
        this.requiredFilters = {
          "dateFilter": true,
          "multiSelectHierarchyMemberFilter": true,
          "companyDropdownFilter": true,
          "hierarchyDropdownFilter": true
        }
        break;
      }
    }
  }
  public addFilterChips(event: CRGridSearch): void {
    this.removedKey = {};
    this.filterService.setSelectedValues([event]);
    if (event?.value) {
      if (this.searchObject.length > 0) {
        const index = this.searchObject.findIndex((x: CRGridSearch) => x.key === event.key);
        if (index === -1) {
          this.searchObject.push(event);
        } else {
          this.searchObject[index] = event;
        }
      } else {
        this.searchObject.push(event);
      }
      if (event.key === "hierarchy" && this.isReportType()) {
        this.getSelectedHierarchyName();
      }
    }
    else {
      const index = this.searchObject.findIndex((x: CRGridSearch) => x.key === event?.key);
      this.removeFilter(index, event);
    }

    this.currentSearchFilter = this.searchObject;
    this.changeDetector.detectChanges();
    // save date to show in generated pdf
    if (event.key == 'monthYear') {
      this.customReportExportPdfService.setDataToPdf(event?.value, this.reportTitle)

    }
  }

  clearFilterChip(key) {
    const index = this.searchObject.findIndex((x: CRGridSearch) => x.key === key);
    this.clearFilterKey = key;
    let removedValue = this.searchObject.splice(index, 1);
    if (key === 'company') {
      this.multiSelectFilter = [];
      const index = this.searchObject.findIndex((x: CRGridSearch) => x.key === 'dealer');
      removedValue = this.searchObject.splice(index, 1);
      const index1 = this.searchObject.findIndex((x: CRGridSearch) => x.key === 'dateFilter');
      removedValue = this.searchObject.splice(index1, 1);
      const index2 = this.searchObject.findIndex((x: CRGridSearch) => x.key === 'hierarchy');
      removedValue = this.searchObject.splice(index2, 1);
    } else {
      this.multiSelectFilter = [];
    }
    // this.filterService.setRemovedValues(removedValue[0]);

  }

  public removeFilter(index: number, searchObj: CRGridSearch): void {
    this.searchObject.splice(index, 1);
    this.filterService.setRemovedValues(searchObj);
  }

  public removeAllFilters(): void {
    this.searchObject = [];
    this.filterService.setRemovedValues({});
  }

  public goBack(): void {
    if (this.isReportType()) {
      this.route.navigateByUrl('/workout/operational');
    } else {
      this.route.navigate(['/workout/dashboard']);
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public arrowClick(val) {
    if (val === true) {
      this.selectedIndex += 1;
    } else {
      this.selectedIndex -= 1;
    }
    const filter = { key: 'dealer', value: this.multiSelectFilter[this.selectedIndex].value };
    const key1 = 'HIERARCHY_ID';
    filter[key1] = this.multiSelectFilter[this.selectedIndex].value.HIERARCHY_ID;
    const key2 = 'REGION_CODE';
    filter[key2] = this.multiSelectFilter[this.selectedIndex].value.REGION_CODE;
    const key3 = 'DISTRICT_CODE';
    filter[key3] = this.multiSelectFilter[this.selectedIndex].value.DISTRICT_CODE;
    const key4 = 'MEMBER_NAME';
    filter[key4] = this.multiSelectFilter[this.selectedIndex].value.MEMBER_NAME;
    this.addFilterChips(filter);
    this.setFilterValues();
  }

  public setFilterValues() {
    this.isFirstTimeLoaded = false;
    this.customerReportFilterService.setSelectedValues(this.searchObject);
    if (this.isReportType()) {
      // this.getSelectedHierarchyName()
    }
  }
  onSearchButtonClick() {
    this.setFilteredValuesBasedOnRequiredFilters();
  }

  setFilteredValuesBasedOnRequiredFilters() {
    if (Object.keys(this.requiredFilters).length === this.searchObject?.length) {
      this.setFilterValues();
    }
  }

  isReportType() {
    return (this.reportTitle === reportType.PARTS_DEPARTMENT || this.reportTitle === reportType.SERVICE_DEPARTMENT || this.reportTitle === reportType.SALES_ANALYSIS_NEW_VEHICLE || this.reportTitle === reportType.SALES_ANALYSIS_USED_VEHICLE || this.reportTitle === reportType.PARTS_ANALYSIS || this.reportTitle === reportType.SERVICE_ANALYSIS);
  }

  getSelectedHierarchyName() {
    const heirarchyFilter: any = this.searchObject.find(val => val.key === "hierarchy");
    const heirarchyFilterName = heirarchyFilter ? this.hierarchyDropdownData.find(val => val.id === heirarchyFilter?.value?.id).name : "";
    if (heirarchyFilter) {
      this.selectedHierarchyName = heirarchyFilterName + ' -';
    }
  }

  async exportAllTables() {
    this.spinnerService.displaySpinner();
    const tabLabels = document.querySelectorAll('.mat-tab-label');
    const originalActiveTab = document.querySelector('.mat-tab-label-active');
    let tablesHtml = '';
    const originTab = originalActiveTab as HTMLElement;

    const styleSheets = Array.from(document.styleSheets)
      .map(styleSheet => {
        try {
          return Array.from(styleSheet.cssRules)
            .map(rule => rule.cssText)
            .join(' ');
        } catch (e) {
          console.error('Accessing stylesheet rules failed', e);
          return '';
        }
      })
      .join(' ');


    for (let i = 0; i < tabLabels.length; i++) {
      const tab = tabLabels[i] as HTMLElement;
      tab.click();

      originalActiveTab?.classList.remove('mat-tab-label-active');
      tab.classList.add('mat-tab-label-active');

      const tabText = tab.querySelector('.mat-tab-label-content')
      if (tabText && tabText.textContent.toLocaleLowerCase().includes('profile')) {
        await new Promise(resolve => setTimeout(resolve, 5000));
      } else {
        await new Promise(resolve => setTimeout(resolve, 2000));
      }
      const tables = document.querySelectorAll('table');
      tablesHtml += Array.from(tables).map(table => table.outerHTML).join('');


      tab.classList.remove('mat-tab-label-active');
    }


    originalActiveTab?.classList.add('mat-tab-label-active');
    originTab.click();

    const finalHtml = `
    <html>
      <head>
        <style>
          ${styleSheets}
          /* Ensure the body is scrollable */
          body {
            height: 100vh;
            overflow-y: auto;
            margin: 0;
            padding: 0;
          }
          table {
            width: 100%;
            margin-bottom: 20px;
          }
        </style>
      </head>
      <body>
        ${tablesHtml}
      </body>
    </html>
  `;

    return finalHtml;
  }


  async exportExcelJS(): Promise<void> {
    this.spinnerService.displaySpinner();
    const parser = new DOMParser();
    const finalHtml = await this.exportAllTables();
    const doc = parser.parseFromString(finalHtml, 'text/html');
    const tables = doc.querySelectorAll('table');
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('All Tables');

    const columnWidths: number[] = [];
    let currentRow = 1;

    const title = this.reportTitle;
    const titleRow = worksheet.addRow([title]);
    titleRow.font = { name: 'Arial', size: 9, bold: true };
    worksheet.mergeCells('A1:C1');
    titleRow.alignment = { vertical: 'middle', horizontal: 'left' };


    let keyValueColumn = 4;

    this.searchObject.forEach(element => {
      const cell = worksheet.getCell(1, keyValueColumn);
      const key = element.key.includes('month') ? 'Date' : element.key.substring(0, 1).toUpperCase() + element.key.substring(1, element.key.length);
      cell.value = `${key}: ${element.value}`;
      cell.font = { name: 'Arial', size: 9, bold: true };
      cell.alignment = { vertical: 'middle', horizontal: 'left' }
      keyValueColumn++;
    });

    currentRow = 3;

    tables.forEach((table) => {
      const rows = table.querySelectorAll('tr');

      const isYtdMtdTable = table.classList.contains('dxfw-ytd-mtd-cell-class');
      const isAltWhtGryTable = table.classList.contains('dxfw-alt-wht-gry-class');
      const isProfileGreyEven = table.classList.contains('dxfw-alt-wht-gry-even-class');

      rows.forEach((row, rowIndex) => {
        const cells = row.querySelectorAll('td, th');
        const excelRow = worksheet.getRow(currentRow);
        let cellIndex = 1;
        const isLastChild = row.matches(':last-child');
        cells.forEach((cell) => {
          // set defaults
          const colspan = cell.getAttribute('colspan');
          const excelCell = excelRow.getCell(cellIndex);
          excelCell.value = cell.textContent || '';
          excelCell.alignment = { vertical: 'middle', horizontal: 'center' };
          excelCell.border = {
            top: { style: 'medium', color: { argb: 'E5EBEB' } },
            left: { style: 'medium', color: { argb: 'E5EBEB' } },
            bottom: { style: 'medium', color: { argb: 'E5EBEB' } },
            right: { style: 'medium', color: { argb: 'E5EBEB' } }
          };
          excelCell.font = { size: 8 }
          // end set defauls

          const textLength = excelCell.value ? excelCell.value.toString().length : 10;
          columnWidths[cellIndex - 1] = Math.max(columnWidths[cellIndex - 1] || 0, textLength);

          if (rowIndex % 2 === 0) {
            if (isYtdMtdTable) {
              // Apply the alternating color for dxfw-ytd-mtd-cell-class (odd rows)
              excelCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'F0D9D2' } // Matching the #F0D9D2 color
              };

            } else if (isAltWhtGryTable || isProfileGreyEven) {
              // Apply the alternating color for dxfw-alt-wht-gry-class (odd rows)
              excelCell.fill = {
                type: 'pattern',
                pattern: 'solid',
                fgColor: { argb: 'F0F0F0' }
              };
            }
          }

          if (isYtdMtdTable && isLastChild) {
            excelCell.fill = {
              type: 'pattern',
              pattern: 'solid',
              fgColor: { argb: 'EBE7B2' } // last child
            };
          }

          if (cell.classList.contains('mat-header-cell')) {
            excelCell.font = { bold: true, size: 9, color: { argb: '262626' } };
            excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'C7C1A9' } };
            excelCell.border = {
              top: { style: 'medium', color: { argb: '9A9064' } },
              left: { style: 'medium', color: { argb: '9A9064' } },
              bottom: { style: 'medium', color: { argb: '9A9064' } },
              right: { style: 'medium', color: { argb: '9A9064' } }
            };
          }

          if (cell.classList.contains('dxfw-custom-report-table-header') || cell.classList.contains('dxfw-profile-header')) {
            excelCell.font = { bold: true, size: 9, color: { argb: '262626' } };
            excelCell.fill = { type: 'pattern', pattern: 'solid', fgColor: { argb: 'C7C1A9' } };
            excelCell.border = {
              top: { style: 'medium', color: { argb: '9A9064' } },
              left: { style: 'medium', color: { argb: '9A9064' } },
              bottom: { style: 'medium', color: { argb: '9A9064' } },
              right: { style: 'medium', color: { argb: '9A9064' } }
            };
          }

          const spanWithRedFont = cell.querySelector('span.dxfw-custom-report-table-cell-highlightcolor');
          if (spanWithRedFont) {
            excelCell.font = {
              color: { argb: 'FFFF0000' }
            };
          }

          if (colspan && Number(colspan) > 1) {
            const mergeEnd = cellIndex + Number(colspan) - 1;
            worksheet.mergeCells(currentRow, cellIndex, currentRow, mergeEnd);
            cellIndex = mergeEnd + 1;
          } else {
            cellIndex++;
          }
        });

        currentRow++;
      });

      currentRow++;
    });

    columnWidths.forEach((width, index) => {
      worksheet.getColumn(index + 1).width = width + 2;
    });

    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      FileSaver.saveAs(blob, `${this.reportTitle}.xlsx`);
    });

    this.spinnerService.hideSpinner();
  }



}


