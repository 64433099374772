import * as _ from 'lodash';
import { Component, EventEmitter, Output, OnInit, ChangeDetectorRef } from "@angular/core";
import { Observable, Subscription, catchError, filter, forkJoin, of } from "rxjs";
import { CRGridSearch } from "src/app/modules/workout-sales/interfaces/custom-reports.interface";
import { SpinnerService } from "src/app/services/spinner.service";
import { CustomReportsFiltersService } from "../../services/custom-report-filters.service";
import { CustomReportsApiService } from "../../services/api/custom-report-api.service";
import { LexusSalesAtGlanceReport } from "../reportConstants";
import { getMonthAndYear, getObjFromFilteredArray } from "../../utils/common-utils";
import { CustomReportsService } from "../../services/custom-report.service";
import { ToastService } from "src/app/services/toast.service";
import { Router } from "@angular/router";
import { reportUrlType } from "src/app/constants/constants";
import { LexusSalesAtGlanceMockData } from '../../mock/mock';
 
@Component({
  selector: 'app-lexus-sales-at-glance',
  templateUrl: './lexus-sales-at-glance.component.html',
  styleUrls: ['./lexus-sales-at-glance.component.scss']
})
export class LexusSalesAtGlanceComponent implements OnInit {
 
  profileHeader: string = 'DEALER PROFILE';
  showDepartmentTable: boolean = false;
 
 
  public obj = Object.values;
  @Output() updateSearch: EventEmitter<CRGridSearch> = new EventEmitter<CRGridSearch>();
 
  public currentTabIndex: number = 0;
  public lexusSalesAtGlancejsonObj: any;
  public selectedFilter = [];
 
  public selectedTabIndex = [0];
  public subscription: Subscription = new Subscription();
  public filtersSubscription: Subscription = new Subscription();
  public isProfileTable: boolean = false;
  public sources: Observable<any>[] = [];
  public tabList: string[] = [];
  public reportType = '';
  public formattedSelectedFilter;
 
  constructor(
    private readonly spinnerService: SpinnerService,
    private filterService: CustomReportsFiltersService,
    private customReportsApiService: CustomReportsApiService,
    private readonly customReportsService: CustomReportsService,
    public route: Router,
    public toast: ToastService
  ) {
    this.tabList = LexusSalesAtGlanceReport.LexusSalesAtGlanceTabNames;
    const reportTypeNameFromUrl = this.route.url.split('/').pop();
    this.reportType = reportUrlType[reportTypeNameFromUrl];
    this.getLexusSalesAtGlanceReport();
  }
 
  ngOnInit(): void {
    this.subscribeReportData();
    this.displayProfileSection();
  }
 
  public subscribeReportData() {
    this.filtersSubscription = this.filterService.getSelectedValues()?.pipe().subscribe({
      next: (filteredValues) => {
        if (filteredValues.length > 0) {
          this.spinnerService.displaySpinner();
          this.selectedFilter = filteredValues;
          if (this.lexusSalesAtGlancejsonObj?.tabList[this.currentTabIndex]?.datasource?.length > 0) {
            this.getLexusSalesAtGlanceReport();
          }
          this.mapResponse();
        }
 
      }, error: (error) => {
        console.error('Error handler:', error)
        this.spinnerService.hideSpinner();
      }
    });
  }
 
  getSelectedYear() {
    this.filterService.getSelectedValues()?.subscribe(val => {
      this.selectedFilter = val
    });
    return this.selectedFilter;
  }
 
  public tabChange(tabIndex: number) {
    this.currentTabIndex = tabIndex;
    const index = this.selectedTabIndex.findIndex(x => x === tabIndex);
    this.spinnerService.displaySpinner();
    if (index === -1) {
      this.selectedTabIndex.push(tabIndex);
      this.filtersSubscription?.unsubscribe();
      this.subscription?.unsubscribe();
      this.subscribeReportData()
    } else {
      this.spinnerService.hideSpinner();
    }
    this.displayProfileSection();
  }
 
  public async mapResponse() {
    //when we have datasource and navigating back to same tab without change in filter do not make api call
    if (this.lexusSalesAtGlancejsonObj?.tabList[this.currentTabIndex]?.datasource?.length === 0) {
      let currentTableResponse = [];
      await this.getTabDataSource(this.currentTabIndex)
      this.subscription = forkJoin(this.sources).subscribe({
        next: (resp) => {
          let dataSources;

          if (this.currentTabIndex === 0) {
            const reportData = resp[0]?.[0].reportData;
            if (reportData && reportData.length > 0) {
              reportData.forEach((sectionArray, index) => {
                sectionArray.forEach((ele) => {
                  if (ele.sectionData?.length > 0) {    
                    const obj = {
                      "datasourceName": `scores`,
                      "tableData":ele.sectionData,
                      "tableHeader": index === 0 ? LexusSalesAtGlanceReport.ScoresHeader : LexusSalesAtGlanceReport.ScoresHeader_1,
                      "columnHeaderName": index === 0 ? this.obj(this.getDisplayedColumns(resp[0]?.[0]?.reportHeaderData,false))
                                                      : this.obj(this.getDisplayedColumns(resp[0]?.[0]?.reportHeaderData,true)),
                      "sectionTitle": ele.SECTIONTITLE,
                      "sectionId": ele.SECTIONID,
                    };
                    currentTableResponse.push(obj);
                  }
                });
              });
            }
          }
          else if (this.currentTabIndex === 1) {
            const reportData = resp[0]?.[0].reportData;
            const reportHeaderData = resp[0]?.[0].reportHeaderData;
            if (reportData && reportData.length > 0) {
              const displayedColumns = this.getDisplayedColumns(resp[0]?.[0]?.reportHeaderData)
              const dateAsOfDate = reportHeaderData[0]?.[0]?.DATA_AS_OF_STR || '';
                reportData.forEach((ele) => {
                  if (ele.sectionData?.length > 0) {
                    const salesHeader = `SALES (${dateAsOfDate})`;
                    const header = { ...LexusSalesAtGlanceReport.SalesHeader[0] };
                    delete header["SALES"];
                    const tableHeader = {
                      [salesHeader]: LexusSalesAtGlanceReport.SalesHeader[0]["SALES"],
                      ...header
                    }
                    const dynamicHeaders = [tableHeader];
                    const obj = {
                      "datasourceName": `sales`,
                      "tableData": ele.sectionData,
                      "tableHeader": dynamicHeaders,
                      "columnHeaderName": this.obj(displayedColumns),
                      "sectionTitle": ele.SECTIONTITLE,
                      "sectionId": ele.SECTIONID,
                    };
                    currentTableResponse.push(obj);
                  }
                });
            }
          }
          else if (this.currentTabIndex === 2) {
            const reportData = resp[0]?.[0].reportData;
            if (reportData && reportData.length > 0) {
              const reportHeaderData = resp[0]?.[0].reportHeaderData;
              const dynamicHeaders =  [{
                [reportHeaderData.H_GROUP_0?.TITLE]: '', // SALES BY MODEL
                [reportHeaderData.H_GROUP_1?.TITLE]: '3',// MONTH END SALES
                [reportHeaderData.H_GROUP_2?.TITLE]: '3',// MONTH END SALES TO AVAILABILITY
                [reportHeaderData.H_GROUP_3?.TITLE]: '3',// MONTH END DAYS SUPPLY
                [reportHeaderData.H_GROUP_4?.TITLE]: '3',// YTD SALES
            }]
              const displayedColumns = this.getDisplayedColumns(resp[0]?.[0]?.reportHeaderData)
                reportData.forEach((ele) => {
                  if (ele.sectionData?.length > 0) {
                    const obj = {
                      "datasourceName": `sales_by_model`,
                      "tableData": ele.sectionData,
                      "tableHeader": dynamicHeaders,
                      "columnHeaderName": this.obj(displayedColumns),
                      "sectionTitle": ele.SECTIONTITLE,
                      "sectionId": ele.SECTIONID,
                    };
                    currentTableResponse.push(obj);
                  }
                });
            }
          }
          else if (this.currentTabIndex === 3) {
            const reportData = resp[0]?.[0].reportData;
            const reportHeaderData = resp[0]?.[0].reportHeaderData;
            if (reportData && reportData.length > 0) {
              const dateAsOfDate = reportHeaderData?.[0].DATA_AS_OF_DTE || '';
                reportData.forEach((ele) => {
                  if (ele.sectionData?.length > 0) {
                    const previousYear = new Date().getFullYear() - 1;
                    const marketHeader = `MARKET SHARE (${dateAsOfDate})`;
                    const ytdChange = `YTD %CHANGE ${previousYear}`;
                    const header = { ...LexusSalesAtGlanceReport.MarketShareHeader[0] };
                    delete header["MARKET SHARE"];
                    delete header["YTD %CHANGE"];
                    delete header["ROLLING 12 MONTHS"];
                    delete header["YTD"];
                    const dynamicHeaders = [{
                        [marketHeader]: LexusSalesAtGlanceReport.MarketShareHeader[0]["MARKET SHARE"],
                        "rolling_12_months": LexusSalesAtGlanceReport.MarketShareHeader[0]["ROLLING 12 MONTHS"],
                        "ytd":LexusSalesAtGlanceReport.MarketShareHeader[0]["YTD"],
                        [ytdChange]:LexusSalesAtGlanceReport.MarketShareHeader[0]["YTD %CHANGE"],
                        ...header, 
                      }];
                    const obj = {
                      "datasourceName": `market_share`,
                      "tableData": ele.sectionData,
                      "tableHeader": dynamicHeaders,
                      "sectionTitle": ele.SECTIONTITLE,
                      "sectionId": ele.SECTIONID,
                    };
                    currentTableResponse.push(obj);
                  }
                });
            }
          }
          else if (this.currentTabIndex === 4) {
            const reportData = resp[0]?.[0].reportData;
            const reportHeaderData = resp[0]?.[0].reportHeaderData;
            if (reportData && reportData.length > 0) {
              const dateAsOfDate = reportHeaderData?.[0].DATA_AS_OF_DTE || '';
                reportData.forEach((ele) => {
                  if (ele.sectionData?.length > 0) {
                    const grossProfitHeader = `GROSS PROFIT (${dateAsOfDate})`;
                    const header = { ...LexusSalesAtGlanceReport.GrossProfitHeader[0] };
                    delete header["GROSS PROFIT"];
                    const dynamicHeaders = [{
                      [grossProfitHeader]: LexusSalesAtGlanceReport.GrossProfitHeader[0]["GROSS PROFIT"],
                      ...header
                    }];
                    const obj = {
                      "datasourceName": `gross_profit`,
                      "tableData": ele.sectionData,
                      "tableHeader": dynamicHeaders,
                      "tableSubHeader": LexusSalesAtGlanceReport.GrossProfitSubHeader,
                      "sectionTitle": ele.SECTIONTITLE,
                      "sectionId": ele.SECTIONID,
                    };
                    currentTableResponse.push(obj);
                  }
                });
            }
          }
          else if (this.currentTabIndex === 5) {
            const reportData = resp;
            if (resp && resp.length > 0) {
              reportData.forEach((sectionArray, index) => {
                sectionArray.forEach((ele) => {
                  if (ele.sectionData?.length > 0) {
                    const obj = {
                      "datasourceName": `dealer_profile`,
                      "tableData": ele.sectionData,
                      "sectionTitle": ele.SECTIONTITLE,
                      "sectionId": ele.SECTIONID,
                    };
                    currentTableResponse.push(obj);
                  }
                });
              });
            }
          }

          if (dataSources) {
            currentTableResponse.push(dataSources)
          }
         
          if (currentTableResponse.length > 0) {
            this.lexusSalesAtGlancejsonObj.tabList[this.currentTabIndex].datasource = currentTableResponse;
          }

          this.spinnerService.hideSpinner();
        }, error: (err) => {
          console.error('Error handler: mapping response', err);
          this.spinnerService.hideSpinner();
        }
      })
    }
    else {
      this.spinnerService.hideSpinner();
    }
  }
 
  getDisplayedColumns(reportHeaderData: any, isRetail: boolean = false) {
    let columnHeaders: string[] = [];
    if (this.currentTabIndex === 0) {
      columnHeaders = isRetail ? [`RETAIL % OBJ (${this.formattedSelectedFilter['monthYear']})`, 'OBJ'] : [`SCORE (${this.formattedSelectedFilter['monthYear']})`, 'OBJ'];
    } else if (this.currentTabIndex === 1) {
      columnHeaders = ['', 'MTD_OBJ'];
    }
    let dynamicHeaders: string[] = [];
    if (Array.isArray(reportHeaderData)) {
      reportHeaderData.forEach((headerDataArray: any) => {
        if (Array.isArray(headerDataArray)) {
          headerDataArray.forEach((header: any) => {
            if (header.COLHDR) {
              dynamicHeaders.push(header.COLHDR);
            }
          });
        }
      });
    }
    columnHeaders = columnHeaders.concat(dynamicHeaders.slice(0, 6));
    if (this.currentTabIndex === 0) {
      columnHeaders.push('DLR', 'DIST', 'AREA', 'COMPANY')
    } else if (this.currentTabIndex === 1) {
      columnHeaders.push('MTD_OBJ_PECT', 'SALES_AS_AREA', 'YTD', 'LYTD', '', '');
    } else if (reportHeaderData && this.currentTabIndex === 2) {
      const yearHeader = reportHeaderData.H_GROUP_1?.HEADERS || {};
      const hGroup2 = reportHeaderData.H_GROUP_2?.HEADERS;
      const hGroup3 = reportHeaderData.H_GROUP_3?.HEADERS;
      const ytdHeader = reportHeaderData.H_GROUP_4?.HEADERS || {};
      columnHeaders = [
        '',
        yearHeader.RPT_YEAR, yearHeader.PRIOR_YEAR, yearHeader.CHANGE,
        hGroup3.DLR, hGroup3.DIST, hGroup3.AREA,
        hGroup2.DLR, hGroup2.DIST, hGroup2.AREA,
        ytdHeader.RPT_YEAR, ytdHeader.PRIOR_YEAR, ytdHeader.CHANGE
      ];
    }
    return columnHeaders;
  }
  
  public getTabDataSource(tabIndex: number) {
    const obj = getObjFromFilteredArray(this.selectedFilter);
    this.formattedSelectedFilter = getObjFromFilteredArray(this.selectedFilter);
    const dateObj = getMonthAndYear(this.formattedSelectedFilter['monthYear']);
    const filterObj = obj['dealer'];
    const requestObj = {
      month: dateObj.searchMonth,
      year: dateObj.searchYear,
      hierarchy: filterObj.HIERARCHY_ID,
      hierarchy_member_id: filterObj.LEVEL_ID,
    }
    if (tabIndex === 0) {
      this.sources = [this.customReportsApiService.getLsaagScores(requestObj).pipe(this.customReportsService.handleError()?.bind(this)),
      ]
    } else if(tabIndex === 1){
      this.sources = [this.customReportsApiService.getLsaagSales(requestObj).pipe(this.customReportsService.handleError()?.bind(this)),
      ]
    } else if(tabIndex === 2){
      this.sources = [this.customReportsApiService.getLsaagSalesByModel(requestObj).pipe(this.customReportsService.handleError()?.bind(this)),
      ]
    } else if(tabIndex === 3){
      this.sources = [this.customReportsApiService.getLsaagMarketShare(requestObj).pipe(this.customReportsService.handleError()?.bind(this)),
      ]
    } else if(tabIndex === 4){
      this.sources = [this.customReportsApiService.getLsaagGrossProfit(requestObj).pipe(this.customReportsService.handleError()?.bind(this)),
      ]
    } else if(tabIndex === 5){
      this.sources = [this.customReportsApiService.getLsaagDealerProfile(requestObj).pipe(this.customReportsService.handleError()?.bind(this)),
      ]
    }
  }
 
  handleError() {
    return function (obsv: Observable<any>) {
      return obsv.pipe(
        catchError((err) => {
          console.log(err);
          this.spinnerService.hideSpinner();
          return err;
        })
      );
    };
  }
 
  public displayProfileSection() {
    this.isProfileTable = this.currentTabIndex === 5;
  }
 
  public getLexusSalesAtGlanceReport() {
    this.lexusSalesAtGlancejsonObj = {
      tabList: [
        {
          tabName: this.tabList[0],
          filterCondition: 'LA - A - 04636 - AN TOYOTA CERRITOS',
          multiDataSources: true,
          datasource: []
        },
        {
          tabName: this.tabList[1],
          filterCondition: '',
          datasource: []
        },
        {
          tabName: this.tabList[2],
          filterCondition: '',
          datasource: []
        },
        {
          tabName: this.tabList[3],
          filterCondition: '',
          datasource: []
        },
        {
          tabName: this.tabList[4],
          filterCondition: '',
          datasource: []
        },
        {
          tabName: this.tabList[5],
          filterCondition: '',
          datasource: []
        },
        {
          tabName: this.tabList[6],
          filterCondition: '',
          datasource: []
        }
      ]
    }
  
}
 
}
 
 