<table [id]="'pdfTable-' + tabIndex" #genericTable  width="100%" *ngIf="!serviceCap" aria-describedby="profile table" class="dxfw-alt-wht-gry-even-class" cellspacing="0" cellpadding="3">
     <!-- fields for lexus-sales-at-glance -->

     <th class="dxfw-profile-header" colspan="12">{{headerTitle}} </th>
    <tr *ngIf="!isTcuv">
        <td colspan="2" class="dxfw-profile-td-cells"> Dealership </td>
        <td colspan="2" style="text-transform: uppercase;">{{dataSource?.DEALER_NAME}}</td>
        <td colspan="2" class="dxfw-profile-td-cells"> Dealer Principal </td>
        <td colspan="2">{{dataSource?.DLR_PRINCIPAL_NAME}}</td>
    </tr>
    <tr *ngIf="!isTcuv">
        <td colspan="2" class="dxfw-profile-td-cells"> Address</td>
        <td colspan="2">{{dataSource?.ADDRESS1}}</td>
        <td colspan="2" class="dxfw-profile-td-cells"> General Manager </td>
        <td colspan="2">Robert Knowles</td>
    </tr>
    <tr *ngIf="!isTcuv">
        <td colspan="2" class="dxfw-profile-td-cells">City </td>
        <td colspan="2">{{dataSource?.CITY}}</td>
        <td colspan="2" class="dxfw-profile-td-cells">Representation</td>
        <td colspan="2"> {{dataSource?.REPRESENT_TXT}}</td>
    </tr>
    <tr *ngIf="!isTcuv">
        <td colspan="2" class="dxfw-profile-td-cells">State/Zip </td>
        <td colspan="2">{{dataSource?.STATE}} , {{dataSource?.ZIP}}</td>
        <td colspan="2" class="dxfw-profile-td-cells">Region/District</td>
        <td colspan="2">{{dataSource?.REGION_NAME}}</td>
    </tr>
    <tr *ngIf="!isTcuv">
        <td colspan="2" class="dxfw-profile-td-cells">Phone </td>
        <td colspan="2" style="text-transform: uppercase;">{{dataSource?.PHONE}}</td>
        <td colspan="2" class="dxfw-profile-td-cells">Market</td>
        <td colspan="2" style="text-transform: capitalize;">{{dataSource?.MARKET}}</td>
    </tr>

    <!-- fields for TCUV -->

    <tr *ngIf="isTcuv">
        <td colspan="2" class="dxfw-profile-td-cells"> Dealership </td>
        <td colspan="2" style="text-transform: uppercase;"> {{dataSource?.DEALER_NAME}}</td>
        <td colspan="2" class="dxfw-profile-td-cells"> Region </td>
        <td colspan="2" style="text-transform: capitalize;"> {{dataSource?.REGION_NAME}} </td>
        <td colspan="2" class="dxfw-profile-td-cells"> Dealer Principal </td>
        <td colspan="2"> {{dataSource?.DLR_PRINCIPAL_NAME}}</td>
    </tr>
    <tr *ngIf="isTcuv">
        <td colspan="2" class="dxfw-profile-td-cells"> Address</td>
        <td colspan="2"> {{dataSource?.ADDRESS1}}</td>
        <td colspan="2" class="dxfw-profile-td-cells"> District </td>
        <td colspan="2"> {{dataSource?.DISTRICT_NAME}}</td>
        <td colspan="2"></td>
        <td colspan="2"></td>
    </tr>
    <tr *ngIf="isTcuv">
        <td colspan="2" class="dxfw-profile-td-cells">City </td>
        <td colspan="2"> {{dataSource?.CITY}}</td>
        <td colspan="2" class="dxfw-profile-td-cells">Market </td>
        <td colspan="2">{{dataSource?.MARKET}}</td>
        <td colspan="2" class="dxfw-profile-td-cells"> General Manager</td>
        <td colspan="2">{{dataSource?.GEN_MGR_NAME}}</td>
    </tr>
    <tr *ngIf="isTcuv">
        <td colspan="2" class="dxfw-profile-td-cells">State/Zip </td>
        <td colspan="2"> {{dataSource?.STATE}} , {{dataSource?.ZIP}}</td>
        <td colspan="2" class="dxfw-profile-td-cells"> District Manager</td>
        <td colspan="2"></td>
        <td colspan="2" class="dxfw-profile-td-cells">Parts manager </td>
        <td colspan="2"></td>
    </tr>
    <tr *ngIf="isTcuv">
        <td colspan="2" class="dxfw-profile-td-cells">Phone </td>
        <td colspan="2" style="text-transform: uppercase;"> {{dataSource?.PHONE}}</td>
        <td colspan="2" class="dxfw-profile-td-cells"> DSPM </td>
        <td colspan="2" style="text-transform: capitalize;">{{dataSource?.DLR_PRINCIPAL_NAME}}</td>
        <td colspan="2" class="dxfw-profile-td-cells">Service Manager</td>
        <td colspan="2"> </td>
    </tr>
    <tr *ngIf="isTcuv">
        <td colspan="2" class="dxfw-profile-td-cells">Representation </td>
        <td colspan="2"> {{dataSource?.REPRESENT_TXT}}</td>
        <td colspan="2" class="dxfw-profile-td-cells">FTS</td>
        <td colspan="2"></td>
        <td colspan="2" class="dxfw-profile-td-cells"> Pre-Owned Manager</td>
        <td colspan="2"></td>
    </tr>
</table>

<table *ngIf="serviceCap && dataSource">
    <thead>
      <tr>
        <th class="dxfw-custom-report-table-header" colspan="12">SERVICE CAPACITY {{(year)}}</th>
      </tr>
    </thead>
    <tbody>
      <tr >
        <td colspan="2" class="dxfw-profile-td-cells">#Technicians</td>
        <td colspan="2" style="text-transform: uppercase;">{{dataSource.TECHNICIANS}}</td>
      </tr>
      <tr >
        <td colspan="2" class="dxfw-profile-td-cells">#ASM's</td>
        <td colspan="2" style="text-transform: uppercase;">{{dataSource.ASMS}}</td>
      </tr>
      <tr >
        <td colspan="2" class="dxfw-profile-td-cells">Total Stalls</td>
        <td colspan="2" style="text-transform: uppercase;">{{dataSource.TOTALSTALLCNT}}</td>
      </tr>
      <tr>
        <td colspan="2" class="dxfw-profile-td-cells">Techs per ASM</td>
        <td colspan="2" style="text-transform: uppercase;">{{dataSource.TECHPERASM}}</td>
      </tr>
      <tr >
        <td colspan="2" class="dxfw-profile-td-cells">Stalls per Tech</td>
        <td colspan="2" style="text-transform: uppercase;">{{dataSource.STALLSPERTECH}}</td>
      </tr>
      <tr >
        <td colspan="2" class="dxfw-profile-td-cells">MTD ROs per ASM</td>
        <td colspan="2" style="text-transform: uppercase;">{{dataSource.MTDROSPERASM}}</td>
      </tr>
      <tr>
        <td colspan="2" class="dxfw-profile-td-cells">MTD ROs per ASM {{year}}</td>
        <td colspan="2" style="text-transform: uppercase;">{{dataSource.MTDROSPERASM_PY}}</td>
      </tr>
      <tr>
        <td colspan="2" class="dxfw-profile-td-cells">MTD RO's per Tech</td>
        <td colspan="2" style="text-transform: uppercase;">{{dataSource.MTDROSPERTECH}}</td>
      </tr>
      <tr>
        <td colspan="2" class="dxfw-profile-td-cells">MTD RO's per Tech{{year}}</td>
        <td colspan="2" style="text-transform: uppercase;">{{dataSource.MTDROSPERTECH_PY}}</td>
      </tr>
    </tbody>
  </table>

<table width="100%" *ngIf="isTcuv" aria-describedby="Department table" class="dxfw-alt-wht-gry-class" cellspacing="0"
    cellpadding="3">
    <th class="dxfw-custom-report-table-header" colspan='1'> Department </th>
    <th class="dxfw-custom-report-table-header" colspan='1'> Sunday </th>
    <th class="dxfw-custom-report-table-header" colspan='1'> Monday </th>
    <th class="dxfw-custom-report-table-header" colspan='1'> Tuesday </th>
    <th class="dxfw-custom-report-table-header" colspan='1'> Wednesday </th>
    <th class="dxfw-custom-report-table-header" colspan='1'> Thursday </th>
    <th class="dxfw-custom-report-table-header" colspan='1'> Friday </th>
    <th class="dxfw-custom-report-table-header" colspan='1'> Saturday </th>
    <tr>
        <td colspan="1" class="dxfw-profile-td-cells">Sales </td>
        <td colspan="1">9:00 am to 7:00 pm</td>
        <td colspan="1">8:00 am to 8:00 pm</td>
        <td colspan="1">8:00 am to 8:00 pm</td>
        <td colspan="1">8:00 am to 8:00 pm</td>
        <td colspan="1">8:00 am to 8:00 pm</td>
        <td colspan="1">8:00 am to 8:00 pm</td>
        <td colspan="1">8:30 am to 7:00 pm</td>
    </tr>
    <tr *ngIf="deptTdaag">
        <td colspan="1" class="dxfw-profile-td-cells">Parts </td>
        <td colspan="1">9:00 am to 7:00 pm</td>
        <td colspan="1">8:00 am to 8:00 pm</td>
        <td colspan="1">8:00 am to 8:00 pm</td>
        <td colspan="1">8:00 am to 8:00 pm</td>
        <td colspan="1">8:00 am to 8:00 pm</td>
        <td colspan="1">8:00 am to 8:00 pm</td>
        <td colspan="1">8:30 am to 7:00 pm</td>
    </tr>
    <tr *ngIf="deptTdaag">
        <td colspan="1" class="dxfw-profile-td-cells">ASM </td>
        <td colspan="1">9:00 am to 7:00 pm</td>
        <td colspan="1">8:00 am to 8:00 pm</td>
        <td colspan="1">8:00 am to 8:00 pm</td>
        <td colspan="1">8:00 am to 8:00 pm</td>
        <td colspan="1">8:00 am to 8:00 pm</td>
        <td colspan="1">8:00 am to 8:00 pm</td>
        <td colspan="1">8:30 am to 7:00 pm</td>
    </tr>
    <tr *ngIf="deptTdaag">
        <td colspan="1" class="dxfw-profile-td-cells">Technician </td>
        <td colspan="1">9:00 am to 7:00 pm</td>
        <td colspan="1">8:00 am to 8:00 pm</td>
        <td colspan="1">8:00 am to 8:00 pm</td>
        <td colspan="1">8:00 am to 8:00 pm</td>
        <td colspan="1">8:00 am to 8:00 pm</td>
        <td colspan="1">8:00 am to 8:00 pm</td>
        <td colspan="1">8:30 am to 7:00 pm</td>
    </tr>
</table>


