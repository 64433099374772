<mat-expansion-panel [expanded]="true">
  <mat-expansion-panel-header class='custom-expansion-padding' [collapsedHeight]="'30px'" [expandedHeight]="'30px'">
    <mat-panel-title class="left-content">Date *</mat-panel-title>
    <mat-panel-title class="right-content" (click)="onClear();$event.stopPropagation()">CLEAR</mat-panel-title>
  </mat-expansion-panel-header>
  <mat-form-field appearance="fill" class="interval">
    <mat-select matNativeControl (selectionChange)="setDefultFilterValues('monthYear',$event.value)"
      [(value)]="defaultSelected" placeholder="Select">
      <mat-option *ngFor="let interval of dateFilterData" [value]="interval.rpt_year_month" >
        {{ interval.rpt_year_month }}
      </mat-option>
    </mat-select>
    <!-- <mat-hint>{{hintLabel}}</mat-hint> -->

  </mat-form-field>
  <mat-error *ngIf="requiredMsg && defaultSelected === ''">{{requiredMsg}}</mat-error>

</mat-expansion-panel>