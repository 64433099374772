<mat-tab-group (focusChange)="tabChange($event.index)">
    <div *ngIf="tcuvDealerAtGlanceJsonObj?.tabList">
      <mat-tab *ngFor="let tab of tcuvDealerAtGlanceJsonObj?.tabList">
        <ng-template mat-tab-label>{{ tab.tabName }}</ng-template>
        <div [ngClass]="tcuvDealerAtGlanceJsonObj?.tabList[currentTabIndex]?.datasource?.length === 0 ? '':'dxfw-content-table-container'">
            <div
              *ngFor="let currdataSource of tcuvDealerAtGlanceJsonObj?.tabList[currentTabIndex]?.datasource; let  k = index" >
             <div *ngFor ="let datasource of currdataSource ; let  i = index ">
              <app-multi-header-table [tableIndex]="k" *ngIf="!isProfileTable" [multiHeaderTableData]='datasource' [reportType]='reportType'>
              </app-multi-header-table>
              <div #pdfElement *ngIf="isProfileTable" class='dxfw-content-table-container'>
                <app-generic-profile-table  [genericProfileTableData]='datasource'[isTcuv]="true"></app-generic-profile-table>
            </div>
          </div>
            </div>
            <div class="dxfw-customreport-no-data-label" *ngIf="tcuvDealerAtGlanceJsonObj?.tabList[currentTabIndex]?.datasource?.length === 0 && selectedFilter?.length !== 0" >Sorry, there are no results that match your search criteria.</div>
            <div class="dxfw-no-data" *ngIf="selectedFilter?.length === 0">Please select the filters...</div> 
        </div>
       
      </mat-tab>
    </div>
  </mat-tab-group>