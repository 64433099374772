import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of, retry, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { IConfig } from '../../../../interface/common.interface';
import { LexusSalesAtGlanceMockData, LexusDealerAtGlancePartsMockData, MockCustomReportListResponseDEV, MockTopTenDealersResponsDEV, PartsDepartmentAnalysisMockData, SalesAnalysisNewVehicleMockData, SalesAnalysisUsedVehicleMockData} from '../../mock/mock';

@Injectable({
  providedIn: 'root'
})
export class CustomReportsCoreApiService {

  private readonly apiConstants: any = {
    getCompanyListAPI: 'custom-reports/companydata',
    getLexusDateAndMonthAPI: 'custom-reports/lexusdatesdata',
    getTCUVDateAndMonthAPI: 'custom-reports/tcuvdatesdata',
    getPartDeptDateAndMonthAPI: 'custom-reports/dates-data',
    getDlrMemHrcyAPI: 'custom-reports/dlrMemHierarchyData',
    getPartDeptDlrMemHrcyAPI: 'custom-reports/dealers-data',
    getResultsForLexusPOSnapshotReportAPI: 'custom-reports/LPOSES/report-data',
    getLexusSalesAtGlanceReportAPI: 'custom-reports/LPOSES/report-data',
    getResultsForTCUVDealerAtGlanceAPI: 'custom-reports/TCUVDG/tcuv-report-data',
    getToyotaDealerAtGlanceSlmSection: 'custom-reports/TOYOTADG/toyotadg-report-data',
    getLexusSalesAtGlanceAPI: 'custom-reports/lexus-saag',
    getPartDepartmentAnalysisReportAPI: 'custom-reports/parts-report-data',
    getServiceDepartmentAnalysisReportAPI: 'custom-reports/service-dept-exp-sales-data',
    getSalesAnalysisNewVehicle: 'custom-reports/sales-analysis-new-vehicle',
    getSalesAnalysisUsedVehicle: 'custom-reports/sales-analysis-used-car',
    getServiceAnalysisReportAPI: 'custom-reports/service-analysis-data',
    getPartsAnalysisReportAPI: 'custom-reports/parts-analysis-data',
    getHrcyMemListData: 'custom-reports/list-hierarchy-members',
    getLexusDealerAtGlanceAPI: 'custom-reports/LEXUSDG/lexusdg-report-data'
  };
  private readonly env: IConfig = environment;

  constructor(
    private readonly httpClient: HttpClient,

  ) { }

  public getMockCompanyList(): Observable<any> {
    const apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getCompanyListAPI}`;
    return this.httpClient.post(apiUrl, {
    }, { observe: 'response' }).pipe(
      retry(1),
      catchError(this._handleError)
    );
  }



  public getDlrAndHrcyMemData(requestObj): Observable<any> {
    const apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getHrcyMemListData}`;
    return this.httpClient.post(apiUrl, requestObj, { observe: 'response' }).pipe(
      retry(1),
      catchError(this._handleError)
    );
  }

  public getPartDeptDlrMemHrcyData(companyCode): Observable<any> {
    const apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getPartDeptDlrMemHrcyAPI}`;
    return this.httpClient.post(apiUrl, {
      "companyCode": companyCode
    }, { observe: 'response' }).pipe(
      retry(1),
      catchError(this._handleError)
    );
  }
  public getLexusDateAndMonth(): Observable<any> {
    const apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getLexusDateAndMonthAPI}`;
    return this.httpClient.post(apiUrl, {
    }, { observe: 'response' }).pipe(
      retry(1),
      catchError(this._handleError)
    );
  }

  public getTCUVDateAndMonth(): Observable<any> {
    const apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getTCUVDateAndMonthAPI}`;
    return this.httpClient.post(apiUrl, {
    }, { observe: 'response' }).pipe(
      retry(1),
      catchError(this._handleError)
    );
  }
  public getPartDeptDateAndMonth(companyCode): Observable<any> {
    const apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getPartDeptDateAndMonthAPI}`;
    return this.httpClient.post(apiUrl, {
      "companyCode": companyCode
    }, { observe: 'response' }).pipe(
      retry(1),
      catchError(this._handleError)
    );
  }

  public getResultsForTopTenMTDSalesDealerNationally(requestObj: any): Observable<any> {
    const apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getResultsForLexusPOSnapshotReportAPI}`;
    return this.httpClient.post(apiUrl, {
      month: requestObj.month,
      year: requestObj.year,
      view_id: 'ytd_top_10'
    }, { observe: 'response' }).pipe(
      retry(1),
      catchError(this._handleError)
    );
  }

  public getLexusPOSnapshotExecutiveSummaryReportYTDSalesData(requestObj: any): Observable<any> {
    const apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getResultsForLexusPOSnapshotReportAPI}`;
    return this.httpClient.post(apiUrl, {
      month: requestObj.month,
      year: requestObj.year,
      view_id: 'ytdsalesgrowth'
    }, { observe: 'response' }).pipe(
      retry(1),
      catchError(this._handleError)
    );
  }

  public getLexusPOSnapshotExecutiveSummaryReportMTDSalesData(requestObj: any): Observable<any> {
    const apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getResultsForLexusPOSnapshotReportAPI}`;
    return this.httpClient.post(apiUrl, {
      month: requestObj.month,
      year: requestObj.year,
      view_id: 'mtdsalesgrowth'
    }, { observe: 'response' }).pipe(
      retry(1),
      catchError(this._handleError)
    );
  }

  public getLexusPOAreaSalesPercYTDSalesData(requestObj: any): Observable<any> {
    const apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getResultsForLexusPOSnapshotReportAPI}`;
    return this.httpClient.post(apiUrl, {
      month: requestObj.month,
      year: requestObj.year,
      view_id: 'areasales-vs-nation-ytd'
    }, { observe: 'response' }).pipe(
      retry(1),
      catchError(this._handleError)
    );
  }


  public getLexusPOAreaSalesPercMTDSalesData(requestObj: any): Observable<any> {
    const apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getResultsForLexusPOSnapshotReportAPI}`;
    return this.httpClient.post(apiUrl, {
      month: requestObj.month,
      year: requestObj.year,
      view_id: 'areasales-vs-nation-mtd'
    }, { observe: 'response' }).pipe(
      retry(1),
      catchError(this._handleError)
    );
  }

  public getResultsForMTDBestRecords(requestObj: any): Observable<any> {
    const apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getResultsForLexusPOSnapshotReportAPI}`;
    return this.httpClient.post(apiUrl, {
      month: requestObj.month,
      year: requestObj.year,
      view_id: 'mtd_best_record'
    }, { observe: 'response' }).pipe(
      retry(1),
      catchError(this._handleError)
    );
  }


  public getResultsForMarketShareRecords(requestObj: any): Observable<any> {
    const apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getResultsForLexusPOSnapshotReportAPI}`;
    return this.httpClient.post(apiUrl, {
      month: requestObj.month,
      year: requestObj.year,
      view_id: 'market_share'
    }, { observe: 'response' }).pipe(
      retry(1),
      catchError(this._handleError)
    );
  }

  public getResultsForLexusPOGroundedVSPurchased(requestObj: any): Observable<any> {
    const apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getResultsForLexusPOSnapshotReportAPI}`;
    return this.httpClient.post(apiUrl, {
      month: requestObj.month,
      year: requestObj.year,
      view_id: 'lexus-po-grounded-vs-purchased'
    }, { observe: 'response' }).pipe(
      retry(1),
      catchError(this._handleError)
    );
  }

  public getResultsForCompetativeSalesAnalysisData(requestObj: any): Observable<any> {
    const apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getResultsForLexusPOSnapshotReportAPI}`;
    return this.httpClient.post(apiUrl, {
      month: requestObj.month,
      year: requestObj.year,
      view_id: 'compt-sales-analysis'
    }, { observe: 'response' }).pipe(
      retry(1),
      catchError(this._handleError)
    );
  }

  public getTCUVProfileInfo(requestObj: any): Observable<any> {
    let apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getResultsForTCUVDealerAtGlanceAPI}`;
    return this.httpClient.post(apiUrl, {
      hierarchy_level_member_rcid: requestObj.hierarchy,
      month: requestObj.month,
      year: requestObj.year,
      view_id: 'profile-info'
    }, { observe: 'response' }).pipe(
      retry(1),
      catchError(this._handleError)
    )
  }

  public getLsaagScores(requestObj: any): Observable<any> {
    let apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getLexusSalesAtGlanceAPI}`;
    return this.httpClient.post(apiUrl, {
      year: requestObj.year,
      month: requestObj.month,
      hierarchy_level_member_rcid: requestObj.hierarchy,
      hierarchy_level_type_rcid: requestObj.hierarchy_member_id,
      view_id: 'lsaag_scores'
    }, { observe: 'response' }).pipe(
      retry(1),
      map(response => {
        return response.body;
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getLsaagSales(requestObj: any): Observable<any> {
    let apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getLexusSalesAtGlanceAPI}`;
    return this.httpClient.post(apiUrl, {
      year: requestObj.year,
      month: requestObj.month,
      hierarchy_level_member_rcid: requestObj.hierarchy,
      hierarchy_level_type_rcid: requestObj.hierarchy_member_id,
      view_id: 'lsaag_sales'
    }, { observe: 'response' }).pipe(
      retry(1),
      map(response => {
        return response.body;
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getLsaagSalesByModel(requestObj: any): Observable<any> {
    let apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getLexusSalesAtGlanceAPI}`;
    return this.httpClient.post(apiUrl, {
      year: requestObj.year,
      month: requestObj.month,
      hierarchy_level_member_rcid: requestObj.hierarchy,
      hierarchy_level_type_rcid: requestObj.hierarchy_member_id,
      view_id: 'lsaag_sales_by_model'
    }, { observe: 'response' }).pipe(
      retry(1),
      map(response => {
        return response.body;
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getLsaagMarketShare(requestObj: any): Observable<any> {
    let apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getLexusSalesAtGlanceAPI}`;
    return this.httpClient.post(apiUrl, {
      year: requestObj.year,
      month: requestObj.month,
      hierarchy_level_member_rcid: requestObj.hierarchy,
      hierarchy_level_type_rcid: requestObj.hierarchy_member_id,
      view_id: 'lsaag_market_share'
    }, { observe: 'response' }).pipe(
      retry(1),
      map(response => {
        return response.body;
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getLsaagGrossProfit(requestObj: any): Observable<any> {
    let apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getLexusSalesAtGlanceAPI}`;
    return this.httpClient.post(apiUrl, {
      year: requestObj.year,
      month: requestObj.month,
      hierarchy_level_member_rcid: requestObj.hierarchy,
      hierarchy_level_type_rcid: requestObj.hierarchy_member_id,
      view_id: 'lsaag_gross_profit'
    }, { observe: 'response' }).pipe(
      retry(1),
      map(response => {
        return response.body;
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getLsaagDealerProfile(requestObj: any): Observable<any> {
    let apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getLexusSalesAtGlanceAPI}`;
    return this.httpClient.post(apiUrl, {
      year: requestObj.year,
      month: requestObj.month,
      hierarchy_level_member_rcid: requestObj.hierarchy,
      hierarchy_level_type_rcid: requestObj.hierarchy_member_id,
      view_id: 'lsaag_dealer_profile'
    }, { observe: 'response' }).pipe(
      retry(1),
      map(response => {
        return response.body;
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getTdaagKeyInsight(requestObj: any): Observable<any> {
    let apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getToyotaDealerAtGlanceSlmSection}`;
    return this.httpClient.post(apiUrl, {
      hierarchy_level_type_rcid: 10,
      hierarchy_level_member_rcid: requestObj.hierarchy,
      year: requestObj.year,
      month: requestObj.month,
      view_id: 'keyinsight'
    }, { observe: 'response' }).pipe(
      retry(1),
      map(response => {
        return response.body;
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getTdaagCustomerHandling(requestObj: any): Observable<any> {
    let apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getToyotaDealerAtGlanceSlmSection}`;
    return this.httpClient.post(apiUrl, {
      hierarchy_level_type_rcid: 10,
      hierarchy_level_member_rcid: requestObj.hierarchy,
      year: requestObj.year,
      month: requestObj.month,
      view_id: 'custhandling'
    }, { observe: 'response' }).pipe(
      retry(1),
      map(response => {
        return response.body;
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getTdaagServiceCapacity(requestObj: any): Observable<any> {
    let apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getToyotaDealerAtGlanceSlmSection}`;
    return this.httpClient.post(apiUrl, {
      hierarchy_level_type_rcid: 10,
      hierarchy_level_member_rcid: requestObj.hierarchy,
      year: requestObj.year,
      month: requestObj.month,
      view_id: 'servicecapacity'
    }, { observe: 'response' }).pipe(
      retry(1),
      map(response => {
        return response.body;
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getTdaagSlm(requestObj: any): Observable<any> {
    let apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getToyotaDealerAtGlanceSlmSection}`;
    return this.httpClient.post(apiUrl,  
      {
      hierarchy_level_type_rcid: 10,
      hierarchy_level_member_rcid: 601,
      month:requestObj.month,
      year: requestObj.year,
      view_id: 'slm-info'
    }
    ,
     { observe: 'response' }).pipe(
      retry(1),
      catchError(this._handleError)
    )
  }

  public getTdaagPartsData(requestObj: any): Observable<any> {
    let apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getToyotaDealerAtGlanceSlmSection}`;
    return this.httpClient.post(apiUrl, {
      hierarchy_level_type_rcid: 10,
      hierarchy_level_member_rcid: requestObj.hierarchy,
      year: requestObj.year,
      month: requestObj.month,
      view_id: 'partsdata'
    }, { observe: 'response' }).pipe(
      retry(1),
      map(response => {
        return response.body;
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getTdaagPartsAccessData(requestObj: any): Observable<any> {
    let apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getToyotaDealerAtGlanceSlmSection}`;
    return this.httpClient.post(apiUrl, {
      hierarchy_level_type_rcid: 10,
      hierarchy_level_member_rcid: requestObj.hierarchy,
      year: requestObj.year,
      month: requestObj.month,
      view_id: 'partsaccessdata'
    }, { observe: 'response' }).pipe(
      retry(1),
      map(response => {
        return response.body;
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }
 
  public getTdaagCustomerConnect(requestObj: any): Observable<any> {
    let apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getToyotaDealerAtGlanceSlmSection}`;
    return this.httpClient.post(apiUrl, {
      hierarchy_level_type_rcid: 10 ,
      hierarchy_level_member_rcid: requestObj.hierarchy,
      year: requestObj.year,
      month: requestObj.month,
      view_id: 'customerconnect'
    }, { observe: 'response' }).pipe(
      retry(1),
      map(response => {
        return response.body;
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }

  public getTdaagSkillSet(requestObj: any): Observable<any> {
    let apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getToyotaDealerAtGlanceSlmSection}`;
    return this.httpClient.post(apiUrl, {
      hierarchy_level_type_rcid: 10,
      hierarchy_level_member_rcid: requestObj.hierarchy,
      year: requestObj.year,
      month: requestObj.month,
      view_id: 'skillset'
    }, { observe: 'response' }).pipe(
      retry(1),
      map(response => {
        return response.body;
      }),
      catchError(err => {
        return throwError(err);
      })
    );
  }
 
  public getTCUVNVSSeriesCertsInfoSeries(requestObj: any): Observable<any> {
    let apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getResultsForTCUVDealerAtGlanceAPI}`;
    return this.httpClient.post(apiUrl, {
      hierarchy_level_member_rcid: requestObj.hierarchy,
      month: requestObj.month,
      year: requestObj.year,
      view_id: 'series-info'
    }, { observe: 'response' }).pipe(
      retry(1),
      catchError(this._handleError)
    )
  }

  public getTCUVNVSSeriesCertsInfoSourceofsales(requestObj: any): Observable<any> {
    let apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getResultsForTCUVDealerAtGlanceAPI}`;
    return this.httpClient.post(apiUrl, {
      hierarchy_level_member_rcid: requestObj.hierarchy,
      month: requestObj.month,
      year: requestObj.year,
      view_id: 'source-of-sales'
    }, { observe: 'response' }).pipe(
      retry(1),
      catchError(this._handleError)
    )
  }

  public getTCUVNVSSeriesCertsInfoComp(requestObj: any): Observable<any> {
    let apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getResultsForTCUVDealerAtGlanceAPI}`;
    return this.httpClient.post(apiUrl, {
      hierarchy_level_member_rcid: requestObj.hierarchy,
      month: requestObj.month,
      year: requestObj.year,
      view_id: 'series-certs-info-comp'
    }, { observe: 'response' }).pipe(
      retry(1),
      catchError(this._handleError)
    )
  }

  public getTCUVFinancialReportData(requestObj: any): Observable<any> {
    let apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getResultsForTCUVDealerAtGlanceAPI}`;
    return this.httpClient.post(apiUrl, 
      {
        hierarchy_level_member_rcid:requestObj.hierarchy,
        year: parseInt(requestObj.year),
        month: parseInt(requestObj.month),
        view_id: 'tcuv-finance'
    }, { observe: 'response' }).pipe(
      retry(1),
      catchError(this._handleError)
    )
  }
  public getTCUVNVSSeriesCertsInfoAgedunits(requestObj: any): Observable<any> {
    let apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getResultsForTCUVDealerAtGlanceAPI}`;
    return this.httpClient.post(apiUrl, {
      hierarchy_level_member_rcid: requestObj.hierarchy,
      month: requestObj.month,
      year: requestObj.year,
      view_id: 'series-certs-info-agedunits'
    }, { observe: 'response' }).pipe(
      retry(1),
      catchError(this._handleError)
    )
  }

  public getResultsForLexusPOSnapshotReport(year: string): Observable<any> {
    let mockResponse = {};
    mockResponse = MockTopTenDealersResponsDEV;
    return of(mockResponse);
  }

  public getLexusDAAGForLexusCompleteMainteance(filteredObj: any): Observable<any> {
    const apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getLexusDealerAtGlanceAPI}`;
    return this.httpClient.post(apiUrl, filteredObj, { observe: 'response' }).pipe(
      retry(1),
      catchError(this._handleError)
    );
  }
  public getLexusDAAGForServiceRetention(filteredObj: any): Observable<any> {
    const apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getLexusDealerAtGlanceAPI}`;
    return this.httpClient.post(apiUrl, filteredObj, { observe: 'response' }).pipe(
      retry(1),
      catchError(this._handleError)
    );
  }

  public getLexusSalesAtGlanceData(year: string): Observable<any> {
    let mockResponse = {};
    mockResponse = LexusSalesAtGlanceMockData;
    return of(mockResponse);
  }

  public getPartsDepartmentAnalysisData(filteredObj: any): Observable<any> {
    const apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getPartDepartmentAnalysisReportAPI}`;
    return this.httpClient.post(apiUrl, filteredObj, { observe: 'response' }).pipe(
      retry(1),
      catchError(this._handleError)
    );
  }
  public getServiceDepartmentAnalysisData(filteredObj: any): Observable<any> {
    const apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getServiceDepartmentAnalysisReportAPI}`;
    return this.httpClient.post(apiUrl, filteredObj, { observe: 'response' }).pipe(
      retry(1),
      catchError(this._handleError)
    );
  }
  public getSalesAnalysisNewVehicle(filteredObj: any): Observable<any> {
    const apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getSalesAnalysisNewVehicle}`;
    return this.httpClient.post(apiUrl, filteredObj, { observe: 'response' }).pipe(
      retry(1),
      catchError(this._handleError)
    );
  }
  public getSalesAnalysisUsedVehicle(filteredObj: any): Observable<any> {
    const apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getSalesAnalysisUsedVehicle}`;
    return this.httpClient.post(apiUrl, filteredObj, { observe: 'response' }).pipe(
      retry(1),
      catchError(this._handleError)
    );
  }

  public getServiceAnalysisData(filteredObj: any): Observable<any> {
    const apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getServiceAnalysisReportAPI}`;
    return this.httpClient.post(apiUrl, filteredObj, { observe: 'response' }).pipe(
      retry(1),
      catchError(this._handleError)
    );
  }

  public getPartsAnalysisData(filteredObj: any): Observable<any> {
    const apiUrl = `${this.env.API_END_POINT}/${this.apiConstants.getPartsAnalysisReportAPI}`;
    return this.httpClient.post(apiUrl, filteredObj, { observe: 'response' }).pipe(
      retry(1),
      catchError(this._handleError)
    );
  }

  private _handleError(error: any) {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      // client-side error
      errorMessage = `Error: ${error.error.message}`;
    } else {
      // server-side error
      //errorMessage = `Server Error: Could no retrieve data.`;
      if (error.error.message) {
        errorMessage = error.error.message;
      } else {
        errorMessage = "Error while loading report data";
      }
      // errorMessage = error.error.text.desc;
    }

    return throwError(errorMessage);
  }
}
