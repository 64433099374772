import { Component, Input, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { PartDepartmentModelComponent } from '../../reports/part-department-model/part-department-model.component';
import { CustomReportsService } from '../../services/custom-report.service';
import { MatDialog } from '@angular/material/dialog';
import { DisplayValues } from '../../reports/reportConstants';
import { reportType } from 'src/app/constants/constants';
 
@Component({
  selector: 'app-multi-header-table',
  templateUrl: './multi-header-table.component.html',
  styleUrls: ['./multi-header-table.component.scss']
})
export class MultiHeaderTableComponent implements OnInit {
  @Input() multiHeaderTableData;
  @Input() reportType;
  @Input() headerData;
  @Input() tableIndex;
  @Input() selectedDateFilter;
  public multiHeaderTabledataSource;
  public multiHeaderdisplayedColumns: string[] = [];
  public tableSubHeaderColumns: string[] = [];
  public tableSubHeaderColumns2: string[] = [];
  public subHeaderColumns : string[] = [];
  public tableFirstHeader;
  public tableFirstHeaderColumns: string[] = [];
  public tableSecondHeader;
  public tableSecondHeaderColums: string[] = [];
  public subHeaderColumns2 : string[] = [];
  public obj = Object.keys;
  public isRegionalTable: boolean = false;
  public displayColumnName: string[] = [];
  public tableHeaderClass: string = '';
  public tableColumnClass: string = '';
  public sectionTitle: string = '';
  public totolColumns:number;
  public hideColumnHeader : boolean = false;
  public sectionId;
  public rowAdded: boolean = false;
 
  constructor(private readonly customReportsService: CustomReportsService,
    private dialogue: MatDialog) {
 
  }
 
  ngOnInit(): void {

    // regional table for styling effect
    this.isRegionalTable = this.multiHeaderTableData?.isRegionalTable;
    this.sectionTitle = this.multiHeaderTableData?.sectionTitle;
    this.sectionId = this.multiHeaderTableData?.sectionId;
    this.headerData = this.multiHeaderTableData?.headerData;
    this.hideColumnHeader = this.multiHeaderTableData?.hideColumnHeader;
 
    //altered columns names for each table
    if (this.multiHeaderTableData?.columnHeaderName)
      this.displayColumnName = this.multiHeaderTableData?.columnHeaderName ? this.multiHeaderTableData?.columnHeaderName : [];
    this.getReportStructure();
    this.tableHeaderClass = this.multiHeaderTableData?.tableHeaderClass;
  }
 
  public getReportStructure() {
    const tableData = this.multiHeaderTableData?.tableData;
    this.subHeaderColumns = this.multiHeaderTableData?.tableSubHeader;
    this.tableFirstHeader = this.multiHeaderTableData?.tableHeader;
    this.tableSecondHeader = this.multiHeaderTableData?.tableHeader;
    this.subHeaderColumns2 = this.multiHeaderTableData?.tableSubHeader2;
    const isReportDataSource = !this.isPartsAndServiceDeptReport() && !this.isSalesAnalysisReport() && this.multiHeaderTableData.datasourceName !== 'tcuvfinance' && this.reportType !== reportType.TOYOTA_DEALER_GLANCE
    && this.reportType !== reportType.LEXUS_SALES_GLANCE && this.multiHeaderTableData.datasourceName !== 'parts_access_data' && this.multiHeaderTableData.datasourceName !== 'parts_data' && this.reportType !== reportType.LEXUS_DEALER_GLANCE;
 
    if (tableData && tableData[0]) {
      if (isReportDataSource) {
        this.multiHeaderdisplayedColumns = Object.keys(tableData[0]);
      } else {
        this.multiHeaderdisplayedColumns = this.getDisplayedColumns(tableData);
      }
      this.multiHeaderdisplayedColumns = this.mapRegionNameToStart(this.multiHeaderdisplayedColumns, "REGION_NAME")
      this.multiHeaderdisplayedColumns = this.mapRegionNameToStart(this.multiHeaderdisplayedColumns, "AREA")
    }
    if (this.displayColumnName && this.displayColumnName?.length > 0) {
      this.displayColumnName = this.mapRegionNameToStart(this.displayColumnName, "")
    }
 
    if (this.subHeaderColumns && this.subHeaderColumns.length > 0) {
      const nestedSubHeaderSet = new Set<string>(); // to avoid duplicates
      this.subHeaderColumns?.forEach((subHeader, index) => {
        const mainHeaderKeys = Object.keys(subHeader);
        mainHeaderKeys?.forEach(mainHeader => {
          nestedSubHeaderSet.add(mainHeader);
        });
      });
      this.tableSubHeaderColumns = Array.from(nestedSubHeaderSet);
      const nestedSubHeaderSet2 = new Set<string>();
 
      this.subHeaderColumns2?.forEach((subHeader, index) => {
        const mainHeaderKeys = Object.keys(subHeader);
        mainHeaderKeys?.forEach(mainHeader => {
          nestedSubHeaderSet2.add(mainHeader);
        });
      });
      this.tableSubHeaderColumns2 = Array.from(nestedSubHeaderSet2);
    }
 
    if (this.tableFirstHeader && this.tableFirstHeader[0]) {
      this.tableFirstHeaderColumns = Object.keys(this.tableFirstHeader[0]);
    }
    if (this.tableSecondHeader && this.tableSecondHeader[0]) {
      this.tableSecondHeaderColums = Object.keys(this.tableSecondHeader[0]);
    }
 
    if (this.multiHeaderTableData.datasourceName === 'source_of_sale' || this.multiHeaderTableData.datasourceName === 'series_report' || this.multiHeaderTableData.datasourceName === 'aged_units') {
      this.tableColumnClass = 'dxfw-custom-report-table-cell-color';
    } else {
      this.tableColumnClass = 'dxfw-custom-report-table-cell-left-align';
    }
    this.multiHeaderTabledataSource = new MatTableDataSource(tableData);
  }
 
  public mapRegionNameToStart(array, name) {
    const indexRegion = array.indexOf(name)
    if (indexRegion !== -1) {
      const replaceRegion = array.splice(indexRegion, 1)[0];
      array.unshift(replaceRegion)
    }
    return array
  }
 
  public transformText(element, col) {
    if (["T_CHANGE", "C_CHANGE"].includes(col)) {
      return element + '%';
    }
    return element;
  }

  public displayCustomizedHeaderName(ele) {
    if (DisplayValues[ele]) {
      return DisplayValues[ele]
    } else if (ele === 'DIST_GP_CY' || ele === 'DIST_GP_PY' || ele === 'DLR_GP_CY' || ele === 'DLR_GP_PY' || ele === 'NTNL_GP_CY' || ele === 'NTNL_GP_PY' || ele === 'RGN_GP_CY' || ele === 'RGN_GP_PY') {
      if ((this.sectionId == 1 || this.sectionId == 5) && this.reportType === reportType.PARTS_DEPARTMENT) {
        return '';
      } else if (this.reportType === reportType.SERVICE_DEPARTMENT) {
        return '%GP';
      } else {
        return 'GP % Sales';
      }
    } else if (ele === 'ACCT_NAME' || ele === 'ACCT_TITLE') {
      return this.sectionTitle;
    }
    else if (ele === 'ROWTYPE') {
      return this.sectionTitle;
    }  else if(ele === this.sectionTitle && this.reportType === reportType.LEXUS_DEALER_GLANCE){
      return this.selectedDateFilter;
    }
    else {
      return ele
    }

  }
  openModel() {
    this.dialogue.open(PartDepartmentModelComponent, {
      width: '620px',
      height: '400px'
    })
  }
 
  checkDifferenceColValue(columnName: string, ele: any) {
    return ((this.isPartsAndServiceDeptReport() || this.reportType === reportType.LEXUS_PO_SNAPSHOT) && ele[columnName] < 0);
  }
 
  checkNullValOfGPColumm(col: string, ele: any) {
    if (this.reportType === reportType.SERVICE_DEPARTMENT && col.includes('_GP') && (ele.ACCT_NO_GP.includes('NA') || ele.ACCT_NO_GP === '')) {
      return true;
    } else if (this.reportType === reportType.PARTS_DEPARTMENT && col.includes('_GP') && ele[col] === null && ele.IS_USER_INPUT === false && ele.ACCT_NO_GP === null && (this.sectionId != 1 && this.sectionId != 5)) {
      return true;
    } else {
      return false;
    }
  }
 
  displayingInput(col: string, ele: any) {
    return (ele?.IS_USER_INPUT === true && col != 'Line#' && col != 'ACCT_NAME');
  }
 
  public getDisplayedColumns(tableData) {
    const hideColumns = ['IS_USER_INPUT', 'SECTIONID', 'SECTIONLINENO', 'ACCT_NO', 'ACCT_NO_GP', 'SECTIONTITLE','SECTIONNO','FONTCOLOR','DEALER_CODE','DISTRICT_CODE','ACCTSEQNO','UNITS_FLAG','REGION_CODE','ACCT_MONTH','ACCTSEQNO','DEALER','ACCT_YEAR',
    'X_FLAVOUR_IND', 'X_ITEMSEQNO','X_ROW_BG_COLOR','X_UNIT_FLG_KEY','X_ACCT_NO','X_FONTCLASS','X_ITEM_NBR','X_SEQ','04080','LA - A - 04080 - PENSKE TOYOTA','X_TYPE_RCID','X_DATA_AS_OF_DTE'
    ,'X_SECTION_TITLE_TXT','X_ENGINE_MEETS_GUIDE_FLG','X_DRIVETRAIN_MEETS_GUIDE_FLG','X_CHASSIS_MEETS_GUIDE_FLG','X_ELECTRICAL_MEETS_GUIDE_FLG','X_HYBRID_MEETS_GUIDE_FLG','X_DISPLAY_SEQ','X_LEVEL_DISPLAY_SEQ','X_ROWTYPE_DISPLAY_SEQ','X_UNIT_FLAG_KEY','LSAAG_X_UNIT_FLAG_KEY'
  ];
    const data = Object.keys(tableData[0])?.filter(((ele: any) => {
      if (!hideColumns.includes(ele)) {
        return ele;
      }
    }));
    if(this.isPartsAndServiceDeptReport()){
    data.unshift('Line#');
    }
    this.totolColumns = data.length;
    return data;
  }
 
  showBorder(col, i) {
    if (i === 0 && this.isPartsAndServiceDeptReport()) {
      return 'column-0';
    } else if(i === 0 && this.isSalesAnalysisReport()){
      return 'column-1'
    } else if (col?.includes('ACCT_NAME')) {
      return 'column-1';
    } else if (col?.includes('_DIFF') && i !== this.totolColumns - 1) {
      return 'border-right';
    } else {
      return 'column'
    }
  }
  isPartsAndServiceDeptReport() {
    return (this.reportType === reportType.SERVICE_DEPARTMENT || this.reportType === reportType.PARTS_DEPARTMENT );
  }
 
  isSalesAnalysisReport() {
    return (this.reportType === reportType.SALES_ANALYSIS_NEW_VEHICLE || this.reportType === reportType.SALES_ANALYSIS_USED_VEHICLE || this.reportType === reportType.SERVICE_ANALYSIS || this.reportType === reportType.PARTS_ANALYSIS);
  }
 
 
}
 
 
 