import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { IUserProfile, UserProfileService } from '@toyota/dd365-platform-library';
import { map, Observable, Subscription } from 'rxjs';
import { SingleSpaProps, singleSpaPropsSubject } from '../single-spa/single-spa-props';
import { Constants } from './constants/constants';
import { SpinnerService } from './services/spinner.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  public profile: IUserProfile | undefined;
  public subscriptions: Subscription = new Subscription();
  public userProfileSubscription: Subscription | undefined;
  private singleSpaPropsSubscription: Subscription | undefined;
  public singleSpaProps: any;
  public showSpinner = false;

  constructor(
    public userProfileService: UserProfileService,
    public changeDetector: ChangeDetectorRef,
    private readonly spinnerService: SpinnerService) {}

  ngOnInit() {
    this.initTimer();
    this.subscriptions.add(this.getSpinnerSubscription().subscribe());
    // Single Spa Props subscription will only get triggered when the app bootstraps
    this.singleSpaPropsSubscription = singleSpaPropsSubject.subscribe(
      (props: SingleSpaProps) => {
        this.singleSpaProps = props;
        // User profile subscription will get triggered any time the DD365 platform broadcasts a user profile update
        this.userProfileSubscription = this.singleSpaProps['userProfile'].subscribe((profile: IUserProfile) => {
          this.userProfileService.updateProfile(profile);       
          this.changeDetector.detectChanges();
        });
        // Add subscription to common component subscription to facilitate easy subscription management
        this.subscriptions.add(this.userProfileSubscription);
      }
    );
    // Add subscription to common component subscription to facilitate easy subscription management
    this.subscriptions.add(this.singleSpaPropsSubscription);
  }

  private initTimer() {
    const setUpTime = localStorage.getItem('forgerock-session');
    const defaultTime = Constants.forgeRockSessionTime;
    if (setUpTime !== null) {
      let currentTime = Date.now();
      if (Number(currentTime) - Number(setUpTime) > defaultTime) {
        localStorage.removeItem('forgerock-session');
      }
    }
  }

  private getSpinnerSubscription(): Observable<void> {
    return this.spinnerService.getSpinner$().pipe(
      map((showSpinner: boolean) => {
        this.showSpinner = showSpinner;
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
    localStorage.removeItem('selected-obj');
    localStorage.removeItem('userIp');

  }

}
