import { Component, EventEmitter, Output, OnInit, ChangeDetectorRef } from "@angular/core";
import { Observable, Subscription, catchError, forkJoin, of } from "rxjs";
import { CRGridSearch } from "src/app/modules/workout-sales/interfaces/custom-reports.interface";
import { SpinnerService } from "src/app/services/spinner.service";
import { CustomReportsFiltersService } from "../../services/custom-report-filters.service";
import { CustomReportsApiService } from "../../services/api/custom-report-api.service";
import { ToyotaDealerAtGlanceReport } from "../reportConstants";
import { getMonthAndYear, getObjFromFilteredArray } from "../../utils/common-utils";
import { CustomReportsService } from "../../services/custom-report.service";
import { ToastService } from "src/app/services/toast.service";
import { reportUrlType } from "src/app/constants/constants";
import { Router } from "@angular/router";
import * as _ from 'lodash';
 
@Component({
  selector: 'app-toyota-dealer-at-glance',
  templateUrl: './toyota-dealer-at-glance.component.html',
  styleUrls: ['./toyota-dealer-at-glance.component.scss']
})
export class ToyotaDealerAtGlanceComponent implements OnInit {
 
  profileHeader: string = 'DEALER PROFILE';
  showDepartmentTable: boolean = false;
 
 
  public obj = Object.values;
  @Output() updateSearch: EventEmitter<CRGridSearch> = new EventEmitter<CRGridSearch>();
 
  public currentTabIndex: number = 0;
  public toyotaDealerAtGlancejsonObj: any;
  public selectedFilter = [];
  public genericProfileTableDataSource;
 
  public selectedTabIndex = [0];
  public subscription: Subscription = new Subscription();
  public filtersSubscription: Subscription = new Subscription();
  public isProfileTable: boolean = false;
  public sources: Observable<any>[] = [];
  public tabList: string[] = [];
  public reportType = '';
  public formattedSelectedFilter;
 
  constructor(
    private readonly spinnerService: SpinnerService,
    private filterService: CustomReportsFiltersService,
    private customReportsApiService: CustomReportsApiService,
    private readonly customReportsService: CustomReportsService,
    public route: Router,
    public toast: ToastService
  ) {
    this.tabList = ToyotaDealerAtGlanceReport.ToyotaDealerAtGlanceTabNames;
    const reportTypeNameFromUrl = this.route.url.split('/').pop();
    this.reportType = reportUrlType[reportTypeNameFromUrl];
    this.getToyotaDealerAtGlanceReport();
  }
 
  ngOnInit(): void {
    this.subscribeReportData();
    this.displayProfileSection();
  }
 
  public subscribeReportData() {
    this.filtersSubscription = this.filterService.getSelectedValues()?.pipe().subscribe({
      next: (filteredValues) => {
        if (filteredValues.length > 0) {
          this.spinnerService.displaySpinner();
          this.selectedFilter = filteredValues;
          if (this.toyotaDealerAtGlancejsonObj?.tabList[this.currentTabIndex]?.datasource?.length > 0) {
            this.getToyotaDealerAtGlanceReport();
          }
          this.mapResponse();
        }
 
      }, error: (error) => {
        console.error('Error handler:', error)
        this.spinnerService.hideSpinner();
      }
    });
  }
 
  getSelectedYear() {
    this.filterService.getSelectedValues()?.subscribe(val => {
      this.selectedFilter = val
    });
    return this.selectedFilter;
  }
 
  public tabChange(tabIndex: number) {
    this.currentTabIndex = tabIndex;
    const index = this.selectedTabIndex.findIndex(x => x === tabIndex);
    this.spinnerService.displaySpinner();
    if (index === -1) {
      this.selectedTabIndex.push(tabIndex);
      this.filtersSubscription?.unsubscribe();
      this.subscription?.unsubscribe();
      this.subscribeReportData()
    } else {
      this.spinnerService.hideSpinner();
    }
    this.displayProfileSection();
  }
 
  public async mapResponse() {
    //when we have datasource and navigating back to same tab without change in filter do not make api call
    if (this.toyotaDealerAtGlancejsonObj?.tabList[this.currentTabIndex]?.datasource?.length === 0) {
      let currentTableResponse = [];
      await this.getTabDataSource(this.currentTabIndex)
      this.subscription = forkJoin(this.sources).subscribe({
        next: (resp) => {
 
          let dataSources;
          let skill_set;
          let skill_set_1;
 
          if (this.currentTabIndex === 0) {
            if (resp[0]?.body)
              dataSources = [{
                "datasourceName": "profileDataSource",
                "tableData": resp[0]?.body,
              }];
          }
          else if (this.currentTabIndex === 1) {
            const responseBody = resp[0]?.[0];
            if (responseBody && responseBody.reportData && Array.isArray(responseBody.reportData)) {
              dataSources = [{
                "tableData": responseBody.reportData || [],
                "tableHeader": ToyotaDealerAtGlanceReport.KeyDealershipInsightHeader,
                "tableSubHeader": ToyotaDealerAtGlanceReport.KeyDealershipInsightSubHeader,
                "datasourceName": "key_dealership",
                "tableHeaderClass": "dxfw-tcuv-header"
              }];
            }
          }
          else if (this.currentTabIndex === 2) {
            if (resp.length > 0 && resp[0].status === 200) {
              let reportData = resp[0]?.body[0]?.reportData;
              if (reportData?.length > 0) {
                const displayedColumns = this.getDisplayedColumns(resp[0]?.body[0]?.reportHeaderData[0],)
                reportData.forEach((ele, index) => {
                  if (ele.sectionData?.length > 0) {
                    const obj = [{
                      "datasourceName": `toyota_loyalty ${index}`,
                      "tableData": ele.sectionData,
                      "sectionTitle": ele.SECTIONTITLE,
                      "sectionId": ele.SECTIONID
                    }];
                    if(!(index === 1)){
                      const header = `TOYOTA LOYALTY and ENGAGEMENT (${this.formattedSelectedFilter['monthYear']})`;
                      const ToyotaLoyaltyHeader = { ToyotaLoyaltyHeader: [{ [header]: '11' }] };          
                      obj[0]["tableHeader"] = ToyotaLoyaltyHeader.ToyotaLoyaltyHeader ;
                      obj[0]['columnHeaderName'] = this.obj(displayedColumns);
                      obj[0]["tableHeaderClass"] = "dxfw-tcuv-header"
                    } else {
                      obj[0]['columnHeaderName'] = this.obj(displayedColumns);
                    }
                    currentTableResponse.push(obj);
                  }
                });
              }
            }
          }
          else if (this.currentTabIndex === 3) {
            const responseBody = resp[0]?.[0]; 
            if (responseBody && responseBody.reportData && Array.isArray(responseBody.reportData)) {
              const header = `CUSTOMER HANDLING (${this.formattedSelectedFilter['monthYear']})`;
              const CustHandlingHeader = { CustHandlingHeader: [{ [header]: '11' }] };
              dataSources = [{
                "tableData": responseBody.reportData || [],
                "tableHeader": CustHandlingHeader.CustHandlingHeader,
                "tableSubHeader": ToyotaDealerAtGlanceReport.CustHandlingSubHeader,
                "headerData" : resp[0]?.[0].reportHeaderData[0],
                "datasourceName": "customer_handling",
                "tableHeaderClass": "dxfw-tcuv-header"
              }]; 
            }
          }
          else if (this.currentTabIndex === 4) {
            const responseBody = resp[0]?.[0];
            const header = `TECHNICIAN SKILL LEVEL GUIDELINES (${this.formattedSelectedFilter['monthYear']})`;
            const TechnicianSkillHeader = { TechnicianSkillHeader: [{ [header]: '11' }] };
            if (responseBody && responseBody.reportData && Array.isArray(responseBody.reportData)) 
              skill_set = [{
                "tableHeader": TechnicianSkillHeader.TechnicianSkillHeader,
                "headerData" : resp[0]?.[0].reportHeaderData[0],
                "tableData": responseBody.reportData || [],
                "datasourceName": "skill_set",
                "tableHeaderClass": "dxfw-tcuv-header"
              }];
              if (resp[1]) 
              skill_set_1 = [{
                "tableData": resp[1]?.[0].reportData[0],
                "datasourceName": "skill_set_1",
              }];  
          }
          else if (this.currentTabIndex === 5) {
            const reportData = resp[0]?.[0].reportData;
            if (reportData.length > 0) {
              const displayedColumns = this.getDisplayedColumns(resp[0]?.[0]?.reportHeaderData[0])
              reportData.forEach((ele, index) => {
                if (ele.sectionData?.length > 0) {
                  const obj = [{
                    "datasourceName": `parts_data`,
                    "tableData": ele.sectionData,
                    "sectionTitle": ele.SECTIONTITLE,
                    "sectionId": ele.SECTIONID,
                  }];
                  if (index === 0) {
                    const header = `SERVICE AND PARTS SALES (${this.formattedSelectedFilter['monthYear']})`;
                   const ServicePartsHeader = { ServicePartsHeader: [{ [header]: '11' }] };          
                    obj[0]['tableHeader'] = ServicePartsHeader.ServicePartsHeader,
                      obj[0]['tableSubHeader'] = ToyotaDealerAtGlanceReport.ServicePartsSubHeader,
                      obj[0]['columnHeaderName'] = this.obj(displayedColumns);
                    obj[0]['hideColumnHeader'] = false;
                  } else {
                    obj[0]['hideColumnHeader'] = true;
                  }
                  currentTableResponse.push(obj);
                }
              });

            }
          }
          else if (this.currentTabIndex === 6) {
            const reportData = resp[0]?.[0].reportData;
            if (reportData.length > 0) {
              const displayedColumns = this.getDisplayedColumns(resp[0]?.[0]?.reportHeaderData[0])
              reportData.forEach((ele, index) => {
                if (ele.sectionData?.length > 0) {
                  const obj = [{
                    "datasourceName": `parts_access_data`,
                    "tableData": ele.sectionData,
                    "sectionTitle": ele.SECTIONTITLE,
                    "sectionId": ele.SECTIONID,
                  }];
                  if (index === 0) {
                    const header = `PARTS AND ACCESSORY PURCHASES (${this.formattedSelectedFilter['monthYear']})`;
                    const PartsHeader = { PartsHeader: [{ [header]: '11' }] };
                    obj[0]['tableHeader'] = PartsHeader.PartsHeader,
                      obj[0]['tableSubHeader'] = ToyotaDealerAtGlanceReport.PartsSubHeader,
                      obj[0]['columnHeaderName'] = this.obj(displayedColumns);
                    obj[0]['hideColumnHeader'] = false;
                  } else if(index === 1){
                    obj[0]["tableHeader"] = ToyotaDealerAtGlanceReport.PartsHeader1;
                    obj[0]['hideColumnHeader'] = true;
                  }
                  currentTableResponse.push(obj);
                }
              });
            }
          }
          else if (this.currentTabIndex === 7) {
            const responseBody = resp[0]?.[0];
            if (responseBody && responseBody.reportData && Array.isArray(responseBody.reportData)) {
              dataSources = [{
                "tableData": responseBody.reportData || [],
                "tableHeader": ToyotaDealerAtGlanceReport.CustomerConnectionHeader,
                "datasourceName": "customer_connect",
                "tableHeaderClass": "dxfw-tcuv-header"
              }];
            }
          }
          if (dataSources) {
            currentTableResponse.push(dataSources);
          }
          if(skill_set){
            currentTableResponse.push(skill_set);
          }
          if(skill_set_1){
            currentTableResponse.push(skill_set_1);
          }
          if (currentTableResponse.length !== 0) {
            this.toyotaDealerAtGlancejsonObj.tabList[this.currentTabIndex].datasource = currentTableResponse;
          }
 
          this.spinnerService.hideSpinner();
        }, error: (err) => {
          console.error('Error handler: mapping response', err);
          this.spinnerService.hideSpinner();
        }
      })
    }
    else {
      this.spinnerService.hideSpinner();
    }
  }

  getDisplayedColumns(reportHeaderData: any) {
    reportHeaderData['RowTitle'] = '';
    reportHeaderData['PYYEAR'] = reportHeaderData.PYEAR;
    reportHeaderData['CYYEAR'] = reportHeaderData.CYEAR;
    const data = _.pick(reportHeaderData, 'RowTitle','TITLE','PYEAR','PREVYEARFORMATVALUE','PREVMONTHPARTVALUE','CURRMONTHPART','CURRYEARFORMATVALUE', 'CYEAR', 'PRIORMTD', 'PYYEAR', 'CYYEAR', 'DEALER', 'DISTRICT', 'REGION','PREVYEARFORMATVALUE',);
    return data;
  }
 
  public getTabDataSource(tabIndex: number) {
    this.formattedSelectedFilter = getObjFromFilteredArray(this.selectedFilter);
    const dateObj = getMonthAndYear(this.formattedSelectedFilter['monthYear']);
    const requestObj = {
      month: dateObj.searchMonth,
      year: dateObj.searchYear,
      hierarchy: this.formattedSelectedFilter['hierarchy'],
      hierarchy_member_id: this.formattedSelectedFilter['dealer'].MEMBER_CODE,
    }
    if (tabIndex === 0) {
      this.sources = [this.customReportsApiService.getTCUVProfileInfo(requestObj).pipe(this.customReportsService.handleError()?.bind(this)),
      ]
    }
    else if (tabIndex === 1) {
      this.sources = [this.customReportsApiService.getTdaagKeyInsight(requestObj).pipe(this.customReportsService.handleError()?.bind(this)),
      ]
    } else if (tabIndex === 2) {
      this.sources = [this.customReportsApiService.getTdaagSlm(requestObj).pipe(this.customReportsService.handleError()?.bind(this)),
      ]
    } else if (tabIndex === 3) {
      this.sources = [this.customReportsApiService.getTdaagCustomerHandling(requestObj).pipe(this.customReportsService.handleError()?.bind(this)),
      ]
    } else if (tabIndex === 4) {
      this.sources = [this.customReportsApiService.getTdaagSkillSet(requestObj).pipe(this.customReportsService.handleError()?.bind(this)),
        this.customReportsApiService.getTdaagServiceCapacity(requestObj).pipe(this.customReportsService.handleError()?.bind(this)),
      ]
    } else if (tabIndex === 5) {
      this.sources = [this.customReportsApiService.getTdaagPartsData(requestObj).pipe(this.customReportsService.handleError()?.bind(this)),
      ]
    } else if (tabIndex === 6) {
      this.sources = [this.customReportsApiService.getTdaagPartsAccessData(requestObj).pipe(this.customReportsService.handleError()?.bind(this)),
      ]
    }
    else if (tabIndex === 7) {
      this.sources = [this.customReportsApiService.getTdaagCustomerConnect(requestObj).pipe(this.customReportsService.handleError()?.bind(this)),
      ]
    }
 
  }
 
  handleError() {
    return function (obsv: Observable<any>) {
      return obsv.pipe(
        catchError((err) => {
          this.spinnerService.hideSpinner();
          return err;
        })
      );
    };
  }
 
  public displayProfileSection() {
    this.isProfileTable = this.currentTabIndex === 0;
  }
 
  public getToyotaDealerAtGlanceReport() {
    this.toyotaDealerAtGlancejsonObj = {
      tabList: [
        {
          tabName: this.tabList[0],
          filterCondition: 'LA - A - 04636 - AN TOYOTA CERRITOS',
          multiDataSources: true,
          datasource: []
        },
        {
          tabName: this.tabList[1],
          filterCondition: '',
          datasource: []
        },
        {
          tabName: this.tabList[2],
          filterCondition: '',
          datasource: []
        },
        {
          tabName: this.tabList[3],
          filterCondition: '',
          datasource: []
        },
        {
          tabName: this.tabList[4],
          filterCondition: '',
          datasource: []
        },
        {
          tabName: this.tabList[5],
          filterCondition: '',
          datasource: []
        },
        {
          tabName: this.tabList[6],
          filterCondition: '',
          datasource: []
        },
        {
          tabName: this.tabList[7],
          filterCondition: '',
          datasource: []
        },
        {
          tabName: this.tabList[8],
          filterCondition: '',
          datasource: []
        }
      ]
    }
  }
}
 
 
 
 