<mat-expansion-panel [expanded]="true">
  <mat-expansion-panel-header class='custom-expansion-padding' [collapsedHeight]="'36px'" [expandedHeight]="'36px'">
    <mat-panel-title class="left-content">{{multiSelectFilterTitle}}</mat-panel-title>
    <mat-panel-title class="right-content" (click)="onClear();$event.stopPropagation()">CLEAR</mat-panel-title>
  </mat-expansion-panel-header>

  <mat-form-field appearance="fill" class="interval">

    <mat-select #select_1 [value]="selectedRows" placeholder="Select" multiple [ngClass]="isDropDownDisbaled ? 'disabled-select' :''"
      (openedChange)="onScrollChange($event,'select_1')">
      <mat-select-trigger *ngIf="selectedRows.length > 0">
        {{ getSelectedNames() }}
      </mat-select-trigger>
      <input matInput class="search-input"  placeholder="Type to search" [formControl]="searchTermInput" (keydown)="onKeyUp($event)">
      <button mat-icon-button class="close-button" *ngIf="selectedRows" (click)="closeMatSelect(select_1,$event)">
        <mat-icon>close</mat-icon>
      </button>
      <div class="select-header" *ngIf="headers.length>0">
        <span class="header-container">
          <span class="header-col-value" *ngFor="let header of headers">{{header}}</span>
        </span>
      </div>

      <mat-option (onSelectionChange)="change($event)" *ngFor="let row of paginatedRows" [value]="row">
        <div class="option-container">
          <span *ngFor="let col of columns" class="col-value">{{row[col] == "NULL" ? '' : row[col]}}</span>
        </div>
      </mat-option>

    </mat-select>
  </mat-form-field>
  <mat-error *ngIf="requiredMsg && selectedRows.length === 0">{{requiredMsg}}</mat-error>
  <mat-error *ngIf="HierarchymemberRequiredMsg && paginatedRows.length === 0">{{HierarchymemberRequiredMsg}}</mat-error>

  <ul class="search-chip" *ngIf="selectedRows.length > 0">
    <ng-container *ngFor="let row of selectedRows; let i = index">
      <li>
        {{row[this.chipFieldToShow]}}
        <em class="tmi tmi-close" (click)="removeSelection(i)"></em>
      </li>
    </ng-container>
  </ul>
</mat-expansion-panel>
