import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { CRGridSearch } from '../../workout-sales/interfaces/custom-reports.interface';

@Injectable({
  providedIn: 'root'
})
export class CustomReportsFiltersService {

  private readonly clearFilters = new BehaviorSubject<CRGridSearch>({});
  private readonly currentFilters = new BehaviorSubject<CRGridSearch[]>([]);
  private readonly currentArrowSearchFilters = new BehaviorSubject<CRGridSearch>({});

  constructor() { }

  public setRemovedValues(removedCol: CRGridSearch): void {
    this.clearFilters.next(removedCol);
  }

  public getRemovedVal(): Observable<CRGridSearch> {
    return this.clearFilters.asObservable();
  }

  public getSelectedValues(): Observable<CRGridSearch[]> {
    return this.currentFilters.asObservable();
  }

  public setSelectedValues(cols: CRGridSearch[]): void {
    this.currentFilters.next(cols);
  }
  public getSelectedArrowSearchValues(): Observable<CRGridSearch> {
    return this.currentArrowSearchFilters.asObservable();
  }

  public setSelecteArrowSearchValues(cols: CRGridSearch): void {
    this.currentArrowSearchFilters.next(cols);
  }

}

