<div class="toyota_dealer_glance">
<mat-tab-group (focusChange)="tabChange($event.index)">
  <div *ngIf="toyotaDealerAtGlancejsonObj?.tabList">
    <mat-tab *ngFor="let tab of toyotaDealerAtGlancejsonObj?.tabList">
      <ng-template mat-tab-label>{{ tab.tabName }}</ng-template>
      <div *ngFor="let currdataSource of toyotaDealerAtGlancejsonObj?.tabList[currentTabIndex]?.datasource">
        <div *ngFor="let datasource of currdataSource ; let  i = index ">
          <app-multi-header-table *ngIf="!isProfileTable && (currentTabIndex !== 4 || (currentTabIndex ===4 && datasource.datasourceName === 'skill_set'))" [multiHeaderTableData]='datasource' [reportType]="reportType">
          </app-multi-header-table>
          <div #pdfElement *ngIf="isProfileTable && currentTabIndex === 0" class='dxfw-content-table-container'>
            <app-generic-profile-table [genericProfileTableData]='datasource' [isTcuv]="true"
              [deptTdaag]="true"></app-generic-profile-table>
          </div>
          <div *ngIf="currentTabIndex === 4 && datasource.datasourceName === 'skill_set_1'">
            <app-generic-profile-table [serviceCapacity]='datasource' [serviceCap]="true"></app-generic-profile-table>
          </div>
        </div>
      </div>
      <div class="dxfw-no-data" *ngIf="selectedFilter?.length === 0">Please select the filters...</div> 
    </mat-tab>
  </div>
</mat-tab-group>
</div>