export class Constants {

  public static readonly forgeRockSessionTime = 45 * 60 * 1000;
  public static readonly FinanceReports = "Workout Financials";

}

export const appConfig = {
  dateFormat: 'MM/DD/YYYY',
  defaultFilterRange: 30,
  dealerId: '24022',
  testMode: false
}

export const filterValues = {
  monthYear: 'monthYear'
}

export const FIELDS_FOR_BulkUserRegistration = {
  userId: 'User Id',
  firstName: 'First Name',
  lastName: 'Last Name',
  email: 'Email',
  accessGroupName: 'Access Group Name',
  roleName: 'Role Names'
};

export const reportType = {
  LEXUS_PO_SNAPSHOT: 'Lexus PO Snapshot Executive Summary',
  TCUV_DEALER_GLANCE: 'TCUV Dealer At A Glance',
  LEXUS_DEALER_GLANCE: 'Lexus Dealer At A Glance',
  LEXUS_SALES_GLANCE: 'Lexus Sales At A Glance Report',
  TOYOTA_DEALER_GLANCE: 'Toyota Dealer At A Glance',
  PARTS_DEPARTMENT: 'Parts Department',
  SERVICE_DEPARTMENT: 'Service Dept Expense And Sales Analysis',
  SALES_ANALYSIS_NEW_VEHICLE: 'Sales Analysis New Vehicle',
  SALES_ANALYSIS_USED_VEHICLE: 'Sales Analysis Used Car',
  SERVICE_ANALYSIS: 'Service Analysis',
  PARTS_ANALYSIS: 'Parts Analysis'
}
export const toyotaReports = [
  reportType.TCUV_DEALER_GLANCE, reportType.TOYOTA_DEALER_GLANCE
]
export const lexusReports = [
  reportType.LEXUS_DEALER_GLANCE, reportType.LEXUS_SALES_GLANCE
]



export const reportUrlType = {
  'lexus-po-snapshot': reportType.LEXUS_PO_SNAPSHOT,
  'tcuv-dealer-at-glance': reportType.TCUV_DEALER_GLANCE,
  'lexus-dealer-at-glance': reportType.LEXUS_DEALER_GLANCE,
  'lexus-sales-at-glance': reportType.LEXUS_SALES_GLANCE,
  'toyota-dealer-at-glance': reportType.TOYOTA_DEALER_GLANCE,
  'part-department-analysis': reportType.PARTS_DEPARTMENT,
  'service-dept-analysis': reportType.SERVICE_DEPARTMENT,
  'sales-analysis-used-vehicle': reportType.SALES_ANALYSIS_USED_VEHICLE,
  'sales-analysis-new-vehicle': reportType.SALES_ANALYSIS_NEW_VEHICLE,
  'service-analysis': reportType.SERVICE_ANALYSIS,
  'parts-analysis': reportType.PARTS_ANALYSIS
}
export enum HIERARCHY_TYPE {
  DEALER = 10,
  DISTRICT = 11,
  REGION = 12,
  NATIONAL = 13
}

export enum COMPANY_TYPE {
  TOYOTA = 14,
  LEXUS = 15
}

export const REPORT_MSGS = {
  NO_RECORDS_FOUND: 'No data available for the selected criteria'
}